import React from 'react';
import {
  AllIcon,
  FeaturedIcon,
  SettingsIcon,
  InvoiceIcon,
  MilestoneIcon,
} from 'commonComponents/Icons';

const ENVIRONMENT_DEVELOPMENT = "development";

export const iconsMap = {
  Supervisor: <SettingsIcon />,
  'Team leader': <InvoiceIcon />,
  Operator: <MilestoneIcon />,
  'iOS Activation': <FeaturedIcon />,
  all: <AllIcon />,
  default: <AllIcon />,
}

/**
 * Validate email.
 * @param {string} - email
 * @return {boolean}
 */
export const isEmail = (email) => /^\w{2,}([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(email);

/**
 * Check if object is empty.
 * @param {object} - Object to be checked
 * @return {boolean}
 */
export const isEmpty = (obj) => obj && Object.entries(obj).length === 0;

/**
 * Get icon for category
 * @param {string} - category
 * @return {string}
 */
export const getIcon = (category) => {
  return iconsMap[category] || iconsMap.default;
};

function is(x, y) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    return x !== x && y !== y; // eslint-disable-line
  }
}

export const isDevelopment = () => {
  return process.env.NODE_ENV.toLowerCase() === ENVIRONMENT_DEVELOPMENT;
}

/**
 * Check if objects are deep equal.
 * @param {object} - Object a to be compared
 * @param {object} - Object b to be compared
 * @return {boolean}
 */
export const deepEqual = (objA, objB) => {
  if (is(objA, objB)) return true;

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  return JSON.stringify(objA) === JSON.stringify(objB)
};

/**
 * Add comma as thousands separator in numbers.
 * @param {number|string} - value to be transformed
 * @return {string}
 */
export const commify = (num) => num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '';

/**
 * Debounce.
 *
 * Example:
 * const debouncer = debounce();
 * debouncer(handler, 250);
 *
 * @return {function}
 */
export const debounce = () => {
  let timeout;

  return (func, delay) => {
    clearTimeout(timeout);
    timeout = setTimeout(func, delay);
  };
};

/**
 * Capitalize.
 * @param {string} - string to be transformed
 * @return {string}
 */
export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Transform labels from "helloWorld" to "Hello World" fromat.
 * @param {string} - value to be transformed
 * @return {string}
 */
export const transformLabels = (label) => {
  const split = capitalize(label).split(/(?=[A-Z])/);
  return split.reduce((acc, item) => `${acc}${/^[A-Z(\s]*$/.test(acc.slice(-1)) ? '' : ' '}${item}`, '');
};

export const hasReadOnlyAccess = () => {
  const userProfile = sessionStorage.getItem('PMCS-User-Profile');
  const readOnlyRole = userProfile && JSON.parse(userProfile).UserRoles.filter((item) => item.includes('ReadOnly'));
  return readOnlyRole?.length > 0;
};

export const withPercent = (value, isAr) => {
  return isAr ? `%${value}` : `${value}%`
};

export const getTaskProjectType = (userRoles = []) => {
  return (userRoles.includes('PCU Manager') ||
    userRoles.includes('PCU TeamLeader') ||
    userRoles.includes('PCU Supervisor')) ? 'PCU' :
    (userRoles.includes('CCTV Manager') ||
      userRoles.includes('CCTV TeamLeader') ||
      userRoles.includes('CCTV Supervisor')) ? 'CCTV' :
      (userRoles.includes('Planner')) ? 'Planner' :
        (userRoles.includes('VIM Manager') ||
          userRoles.includes('VIM TeamLeader') ||
          userRoles.includes('VIM Supervisor')) ? 'VIM' :
          userRoles.includes('WIM Supervisor') ? 'WIM Attendance' :
            userRoles.includes('Feasibility Admin') ? 'Feasibility' :
              userRoles.includes('Crane TeamLeader') ? 'Crane Operator' :
                userRoles.includes('Implementation Admin') ? 'Implementation' :
                  userRoles.includes('Audit') ? 'Audit' :
                    userRoles.includes('RASED Reports') ? 'RASEDReports' :
                      (userRoles.includes('MS Manager') ||
                        userRoles.includes('MS TeamLeader') ||
                        userRoles.includes('MS Supervisor')) ? 'Mobile Speed' : '';
};
export const Paginator = (items, page, perPage) => {
  var page = page || 1,
    perPage = perPage || 10,
    offset = (page - 1) * perPage,
    paginatedItems = items.slice(offset).slice(0, perPage),
    totalPages = Math.ceil(items.length / perPage);
  return {
    page: page,
    perPage: perPage,
    prePage: page - 1 ? page - 1 : null,
    nextPage: (totalPages > page) ? page + 1 : null,
    total: items.length,
    totalPages: totalPages,
    data: paginatedItems
  };
}

export const validateEdit = (keys) => {
  const booleans = keys.map(l => !l?.toString()?.includes("=")
    && !l?.toString()?.includes("+")
    && !l?.toString()?.includes("/")
    && !l?.toString()?.includes("|")
    && !l?.toString()?.includes("<")
    && !l?.toString()?.includes(">")
    && l?.toString()?.charAt(0) !== '='
    && l?.toString()?.charAt(0) !== '+'
    && l?.toString()?.charAt(0) !== '-'
    && l?.toString()?.charAt(0) !== '@'
    && !l?.toString()?.includes("!"))
  return booleans.includes(false);
}
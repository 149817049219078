import { unstable_createMuiStrictModeTheme as createMuiTheme, } from '@material-ui/core/styles';
import typography from './typography';

export const colors = {
  white: '#FFFFFF',
  black: '#000000',

  blue: '#0099DB',
  blueDark: '#337AB7',
  blueDarker: '#1942D6',

  red: '#DE1C1C',
  orange: '#F3A21C',
  teal: '#00857C',
  green: '#2DAB44',

  // primary: '#00A9E2',
  primary: '#612551',
  secondary: '#8A989C',

  tahakom10: '#F4E8F2',
  tahakom20: '#DECCDA',

  purple: '#8E3B6D',

  // #8E44AD

  grey: '#D3D3D3',
  greyLight: '#F3F5F5',
  greyMain: '#143039',
  greyF9:'#F9FBFC',

  grey2: '#F9FBFC',
  grey5: '#F3F5F5',
  grey10: '#E8EAEB',
  grey30: '#B1BABD',
  grey50: '#8A989C',
  grey70: '#5B6E74',
};

const breakpointValues = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const theme = createMuiTheme({
  colors: colors,
  palette: {
    // type: 'dark',
    primary: {
      main: colors.primary,
      button: colors.purple,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      main: colors.greyMain,
      primary: colors.primary,
      secondary: colors.secondary,
      title: colors.primary,
      link: colors.blueDark,
      white: colors.white
    },
    link: {
      active: colors.blue,
      main: colors.grey,
      white: colors.white,
    },
    action: {
      disabled: colors.grey,
      disabledBackground: colors.grey,
    },
    error: {
      main: colors.red,
    },
    info: {
      main: colors.blue,
    },
    infov2: {
      main: colors.white,
    },
    warning: {
      main: colors.orange,
    },
    success: {
      main: colors.green,
    },
    background: {
      main: colors.white,
      menu: colors.white,
      grey: colors.greyLight,
      linkActive: colors.greyF9,
      primary: colors.primary,
      details: colors.greyF9,
      blueDarker: colors.blueDarker
    },
    border: {
      grey: colors.greyLight,
      dark: colors.grey10,
      blue: colors.blue,
      primary: colors.primary,
    },
    background: {
      main: colors.white,
      primary: colors.primary,
      light: colors.grey10,
      tahakom10: colors.tahakom10,
      tahakom20: colors.tahakom20,
    }
  },
  typography: typography(colors.greyMain),
  shape: {
    borderRadius: 0,
  },
  font: {
    main: 'OpenSans',
    title: 'OpenSans',
  },
  breakpoints: { values: breakpointValues },
  zIndex: {
    modal: 998,
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
      },
    },
    MuiIconButton: {
      root: {
        color: colors.black,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 24,
      },
    },
    MuiAppBar: {
      colorPrimary: { backgroundColor: colors.black },
      root: {
        zIndex: 110,
      },
    },
    MuiBadge: {
      colorPrimary: { backgroundColor: colors.red },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px) scale(0.85)',
      },
    },
  },
  props: {
    MuiOutlinedInput: {
      margin: 'dense',
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  boxShadow: {
    container: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
});

export default theme;

import appIcon from '../assets/img/links/app-store.svg';
import appSoonIcon from '../assets/img/links/app_store_coming_soon.png';
import { ReactComponent as PMCSv2Icon } from 'assets/img/links/PMCSv2.svg';
import { ReactComponent as ProgressIcon } from 'assets/img/links/progress.svg';
import { ReactComponent as InventoryIcon } from 'assets/img/links/inventory.svg';
import { ReactComponent as InspectionIcon } from 'assets/img/links/field-inspection.svg';
import { ReactComponent as EngineerIcon } from 'assets/img/links/engineer.svg';
import { ReactComponent as WorkersIcon } from 'assets/img/links/workers.svg';
import { ReactComponent as DashboardIcon } from 'assets/img/links/dashboard.svg';
import { ReactComponent as ShieldIcon } from 'assets/img/links/shield.svg';
import { ReactComponent as SupportIcon } from 'assets/img/links/support.svg';
import { ReactComponent as TrainingIcon } from 'assets/img/links/training.svg';

export default {
  sections: [
    {
      title: 'Apps',
      links: [
        {
          icon: PMCSv2Icon,
          link: 'https://pmcs.stc.com.sa/PMCSV2',
          label: 'PMCSv2(IPMPLS, IGW, Transmission ,TMS, Network Planning)',
          type: 'svg',
        },
        {
          icon: ProgressIcon,
          link: `${process.env.PUBLIC_URL}/resource-assignment`,
          label: 'Site Progress',
          type: 'svg',
        },
        {
          icon: InventoryIcon,
          link: 'https://pmcs.stc.com.sa/pmcs-material',
          label: 'Material Management',
          type: 'svg',
        },
        {
          icon: InspectionIcon,
          link: 'https://pmcs.stc.com.sa/fieldinspection/',
          label: 'Field Inspection',
          type: 'svg',
        },
        {
          icon: EngineerIcon,
          link: 'https://pmcs.stc.com.sa/eQAITweb/PmcsAuth/CreateEQAITUserContext/?username=',
          label: 'eQAIT',
          type: 'svg',
        },
        {
          icon: WorkersIcon,
          link: 'https://pmcs.stc.com.sa/tawal-stc-comp',
          label: 'Tawal - Due Diligence',
          type: 'svg',
        },
        {
          icon: ShieldIcon,
          link: 'https://pmcs.stc.com.sa/IntegratedSecurity/',
          label: 'Security Module',
          type: 'svg',
          // role: 'Security Admin',
        },
        {
          icon: SupportIcon,
          link: 'https://pmcs.stc.com.sa/hrd/',
          label: 'Contractor Resource Management',
          type: 'svg',
          // role: 'HRD Users',
        },
        {
          icon: TrainingIcon,
          link: 'https://pmcs.stc.com.sa/TrainingVideo/',
          label: 'Contractor Training',
          type: 'svg',
          // role: 'HRD Users',
        },
      ]
    },
    {
      title: 'Dashboard & Reports',
      links: [
        {
          icon: DashboardIcon,
          link: 'https://pmcs.stc.com.sa/osp_dashboard/',
          label: 'OSP Dashboard',
          type: 'svg',
        },
        {
          icon: DashboardIcon,
          link: 'https://pmcs.stc.com.sa/pmcsdashboard/',
          label: 'PMCS Dashboard',
          type: 'svg',
        },
        {
          icon: DashboardIcon,
          link: 'https://pmcs.stc.com.sa/dashboard/',
          label: 'Executive Dashboard',
          type: 'svg',
        },
        {
          icon: DashboardIcon,
          link: 'https://pmcs.stc.com.sa/pmcsdashboardv2/',
          label: 'PMCS Dashboard V2',
          type: 'svg',
        }
      ]
    },
    {
      title: 'Mobile Apps',
      links: [
        {
          icons: [
            {
              icon: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
              link: 'https://play.google.com/store/apps/details?id=com.pmcs_scanner&utm_source=pmcs-home&utm_campaign=pmcs-home&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
            },
            {
              icon: appIcon,
              link: 'https://apps.apple.com/ke/app/pmcs-qr/id1171649416',
            },
          ],
          label: 'PMCS QR Scanner'
        },
        {
          icons: [
            {
              icon: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
              link: 'https://play.google.com/store/apps/details?id=com.eqait.fieldinspectiontool&utm_source=pmcs-home&utm_campaign=pmcs-home&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
            },
            {
              icon: appSoonIcon,
              link: '',
            },
          ],
          label: 'eQAIT'
        },
        {
          icons: [
            {
              icon: 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
              link: 'https://play.google.com/store/apps/details?id=com.ntww.eqaitlite&utm_source=pmcs-home&utm_campaign=pmcs-home&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
            },
            {
              icon: appIcon,
              link: 'https://apps.apple.com/ke/app/eqaitlite/id1463364662',
            },
          ],
          label: 'eQAIT Lite'
        },
      ]
    },
  ]
};

import urlConfig from './urlConfig';
import homeLinks from './homeLinks';
import siteDetailsConfig from './siteDetails';
import dashboardConfig from './dashboard';
import {accessControlMapping, restrictedAccessControlMapping} from './accessControlMapping';

const mapApiKey = 'AIzaSyCscN2ly2eaD8BhkMIOViLXiOuvmBY69Cw';

export {
  urlConfig,
  homeLinks,
  siteDetailsConfig,
  dashboardConfig,
  accessControlMapping,
  restrictedAccessControlMapping,
  mapApiKey,
}

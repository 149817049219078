export const errors = {
  invalidLogin: 'Error Occured, please try again later or contact your system administrator.',
  invalidRequest: 'Invalid Request',
  loginError: 'Username or Password is Incorrect',
  loginError1: 'Please enter a valid user name and password!',
  emailError: 'Please enter a valid email',
  passError: 'Please enter a password',
  matchError: 'Please make sure the new passwords are matching.',
  otpSent: 'One Time Passcode has been sent to your registered mobile number.',
  otpUnable:' Unable to resend OTP!',
  otpInvalid: 'Something went wrong. Invalid token.',
  otpReenter: 'Invalid OTP, re-enter the OTP',
  otpError: 'Your account is not yet acticvated for One time password SMS service! Please contact STC technical support team',
  errorOccured: 'Error Occured while changing the password, please try again later or contact your system administrator.',
  resetError: 'Entered password was used recently. Please enter a new one!',
};

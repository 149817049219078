import actionTypes from '../actions/actionTypes';
// import { parseDetails } from './utils';

const INITIAL_STATE = {
  tasks: [],
  inspectors: [],
  operators: [],
  contractors: [],
  projectTypes: [],
  selectedTypeId: 2,
  selectedTasks: [],
  masterDataLookup: {},
  siteDetailsForRouteInfo: [],
  appointmentTasksForOperator: [],
  isLoading: true,
  error: '',
  selectedSiteNames: [],
  loadUsers: false,
  usersList: [],
  usersError: ''
};

const convertToDate = (date) => date ? new Date(date).toLocaleDateString() : '';

const parseTasks = (tasks) => tasks.map((task) => ({
  ...task,
  AssignedDate: convertToDate(task.AssignedDate),
  SurveyRequestDate: convertToDate(task.SurveyRequestDate),
}));

const parseInspectors = (inspectors) => inspectors.map((inspector) => ({
  ...inspector,
  id: inspector.InspectorId,
  name: inspector.FirstName ?
    `${inspector.FirstName} ${inspector.LastName}` :
    inspector.InspectorName
}));
const parseOperators = (operators) => operators.map((operator) => ({
  ...operator,
  id: operator.InspectorId,
  name: operator.FirstName ?
    `${operator.FirstName} ${operator.LastName}` :
    operator.InspectorName
}));
const parseContractors = (contractors) => contractors.map((contractor) => ({
  ...contractor,
  id: contractor.ContractorId,
  name: contractor.FirstName ?
    `${contractor.FirstName} ${contractor.LastName}` :
    contractor.ContractorName
}));

export const resourceAssignment = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.PROJECT_TYPES_SUCCESS:
      return {
        ...state,
        projectTypes: payload,
        selectedTypeId: payload[1]?.TaskTypeId,
      };

    case actionTypes.GET_INSPECTORS_SUCCESS:
      return {
        ...state,
        inspectors: parseInspectors(payload),
      };
    case actionTypes.GET_OPERATORS_SUCCESS:
      return {
        ...state,
        operators: parseOperators(payload),
      };

    case actionTypes.GET_CONTRACTOR_SUCCESS:
      return {
        ...state,
        contractors: parseContractors(payload),
      };

    case actionTypes.GET_TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.GET_SITE_ROUTE_INFO_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.GET_SITE_ROUTE_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.GET_SITE_ROUTE_INFO_SUCCESS:
      return {
        ...state,
        siteDetailsForRouteInfo: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.GET_TASKS_ERROR:
      return {
        ...state,
        tasks: [],
        isLoading: false,
        error: payload,
      };
    case actionTypes.GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: parseTasks(payload),
        isLoading: false,
        error: '',
      };

    case actionTypes.GET_MASTER_DATA_LOOKUP_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.GET_MASTER_DATA_LOOKUP_ERROR:
      return {
        ...state,
        masterDataLookup: {},
        isLoading: false,
        error: payload,
      };
    case actionTypes.GET_MASTER_DATA_LOOKUP_SUCCESS:
      return {
        ...state,
        masterDataLookup: (payload && payload.length) ? payload[0] : {},
        isLoading: false,
        error: '',
      };

    case actionTypes.GET_APPOINTMENT_TASK_FOR_OPERATOR_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.GET_APPOINTMENT_TASK_FOR_OPERATOR_ERROR:
      return {
        ...state,
        appointmentTasksForOperator: [],
        isLoading: false,
        error: payload,
      };
    case actionTypes.GET_APPOINTMENT_TASK_FOR_OPERATOR_SUCCESS:
      return {
        ...state,
        appointmentTasksForOperator: (payload && payload.length) ? payload : [],
        // isLoading: false,
        error: '',
      };
    case actionTypes.ASSIGN_TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.ASSIGN_TASKS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.ASSIGN_TASKS_SUCCESS:
      return {
        ...state,
        selectedTasks: [],
        isLoading: false,
        error: '',
      };
    case actionTypes.REASSIGN_TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.REASSIGN_TASKS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.REASSIGN_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
      };
    case actionTypes.REMOVE_ASSIGN_TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.REMOVE_ASSIGN_TASKS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.REMOVE_ASSIGN_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
      };
    case actionTypes.SELECT_TASKS:
      return {
        ...state,
        selectedTasks: payload,
      };
    case actionTypes.SELECT_TASKS_TYPE:
      return {
        ...state,
        selectedTypeId: payload,
      };

    case actionTypes.SELECTED_SITE:
      return {
        ...state,
        selectedSiteNames: payload,
      };

    case actionTypes.GET_ALL_USERS_LIST_REQUEST:
      return {
        ...state,
        loadUsers: true,
      };
    case actionTypes.GET_ALL_USERS_LIST_SUCCESS:
      return {
        ...state,
        loadUsers: false,
        usersList: payload,
        usersError: ''
      };
    case actionTypes.GET_ALL_USERS_LIST_ERROR:
      return {
        ...state,
        loadUsers: false,
        usersError: payload
      };
      
    default:
      return state;
  }
};

export default {
  GET_INFO_LOADING: '@DASHBOARD_GET_INFO_LOADING',
  GET_INFO_SUCCESS: '@DASHBOARD_GET_INFO_SUCCESS',
  GET_INFO_ERROR: '@DASHBOARD_GET_INFO_ERROR',

  GET_DASHBOARD_INFO_LOADING: '@DASHBOARD_GET_DASHBOARD_INFO_LOADING',
  GET_DASHBOARD_INFO_SUCCESS: '@DASHBOARD_GET_DASHBOARD_INFO_SUCCESS',
  GET_DASHBOARD_INFO_ERROR: '@DASHBOARD_GET_DASHBOARD_INFO_ERROR',
  GET_DASHBOARD_LIVE_INFO_SUCCESS: '@DASHBOARD_GET_DASHBOARD_LIVE_INFO_SUCCESS',

  GET_SITES_LIST_LOADING: '@DASHBOARD_GET_SITES_LIST_LOADING',
  GET_SITES_LIST_SUCCESS: '@DASHBOARD_GET_SITES_LIST_SUCCESS',
  GET_SITES_LIST_ERROR: '@DASHBOARD_GET_SITES_LIST_ERROR',

  SET_SELECTED_SYSTEM: '@DASHBOARD_SET_SELECTED_SYSTEM',
  SET_SLIDER_AUTOPLAY: '@DASHBOARD_SET_SLIDER_AUTOPLAY',
  SET_COLOR_THEME: '@DASHBOARD_SET_COLOR_THEME',
  SET_SCREEN_MODE: '@DASHBOARD_SET_SCREEN_MODE',
  SET_CONNECTION_STATUS: '@DASHBOARD_SET_CONNECTION_STATUS',

};

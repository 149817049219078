export default {
  SITE_DETAILS_LOADING: '@ODOO_SITE_DETAILS_LOADING',
  SITE_DETAILS_SUCCESS: '@ODOO_SITE_DETAILS_SUCCESS',
  SITE_DETAILS_ERROR: '@ODOO_SITE_DETAILS_ERROR',

  DEVIATION_HISTORY_LOADING: '@ODOO_DEVIATION_HISTORY_LOADING',
  DEVIATION_HISTORY_SUCCESS: '@ODOO_DEVIATION_HISTORY_SUCCESS',
  DEVIATION_HISTORY_ERROR: '@ODOO_DEVIATION_HISTORY_ERROR',

  SUBMIT_FEEDBACK_LOADING: '@ODOO_SUBMIT_FEEDBACK_LOADING',
  SUBMIT_FEEDBACK_SUCCESS: '@ODOO_SUBMIT_FEEDBACK_SUCCESS',
  SUBMIT_FEEDBACK_ERROR: '@ODOO_SUBMIT_FEEDBACK_ERROR',

  SET_REJECTION_REASON: '@ODOO_SET_REJECTION_REASON',

};

const urlConfig = process.env.REACT_APP_TEST ? {
  // domain: 'http://eqaitsrv8580.cloudapp.net',
  // domain: 'https://eqait.tahakom.com',
  domain: process.env.REACT_APP_DOMAIN || window.location.origin,
  toolsDomain: 'https://tools.ntww.com',
  // toolsDomain: 'https://eqait.tahakom.com',
  // toolsDomain: 'https://eqaittest.tahakom.com',
  commonAPI: '/PMCSCommonService.API/api/common',
  siteInspectionAPI: `/${process.env.REACT_APP_WEBAPI}/api/Inspection`,
  inspectionCheckListAPI: `/${process.env.REACT_APP_WEBAPI}/api/InspectionCheckList`,
  wimAttendance: `/${process.env.REACT_APP_WEBAPI}/api/WIMAttendance`,
  Reports: `/${process.env.REACT_APP_WEBAPI}/api/Reports`,
  sitePlanAPI: `/${process.env.REACT_APP_WEBAPI}/api/Inspection`,
  uploadSitePlanAPI: `/${process.env.REACT_APP_WEBAPI}/api/ImportData`,
  tahakomCommonAPI: `/${process.env.REACT_APP_WEBAPI}/api/Common`,
  configurationAPI: `/${process.env.REACT_APP_WEBAPI}/api/Configuration`,
  dashboardAPI: '/TahakomDashboardApi',
  dashboardv2API: '/TahakomDashboardApi_v2',
  taskReviewsAPI: `/${process.env.REACT_APP_WEBAPI}/api/TaskReview`,
  dispatchAPI: `/${process.env.REACT_APP_WEBAPI}/api/Dispatch`,
  inspectionChecklistAPI: `/${process.env.REACT_APP_WEBAPI}/api/InspectionCheckList`,
  operationSystemAPI: `/${process.env.REACT_APP_WEBAPI}/api/OperationSystem`,
  cancelTasksAPI: `/${process.env.REACT_APP_WEBAPI}/api/CancelTasks`,
  vpc: `/${process.env.REACT_APP_WEBAPI}/api/VPC`,
  operationalDetails: `/${process.env.REACT_APP_WEBAPI}/api/OperationalDetails`,
  Plan: `/${process.env.REACT_APP_WEBAPI}/api/Plan`,
  ImageGallery: `/${process.env.REACT_APP_WEBAPI}/api/ImageGallery`,
  SystemIntegration: `/${process.env.REACT_APP_WEBAPI}/api/SystemIntegration`,
} : {
  domain: process.env.REACT_APP_DOMAIN || window.location.origin,
  toolsDomain: process.env.REACT_APP_DOMAIN || window.location.origin,
  commonAPI: '/PMCSCommonService.API/api/common',
  siteInspectionAPI: '/eQAIT.Api/api/Inspection',
  inspectionCheckListAPI: '/eQAIT.Api/api/InspectionCheckList',
  // inspectionCheckListEgateWebAPI: '/eGateWebAPI/api/InspectionCheckList',
  wimAttendance: '/eQAIT.Api/api/WIMAttendance',
  Reports: '/eQAIT.Api/api/Reports',
  sitePlanAPI: '/eQAIT.Api/api/Inspection',
  uploadSitePlanAPI: '/eQAIT.Api/api/ImportData',
  tahakomCommonAPI: '/eQAIT.Api/api/Common',
  configurationAPI: '/eQAIT.Api/api/Configuration',
  dashboardAPI: '/TahakomDashboardApi',
  dashboardv2API: '/TahakomDashboardApi_v2',
  taskReviewsAPI: '/eQAIT.Api/api/TaskReview',
  dispatchAPI: '/eQAIT.Api/api/Dispatch',
  operationSystemAPI: '/eQAIT.Api/api/OperationSystem',
  inspectionChecklistAPI: '/eQAIT.Api/api/InspectionCheckList',
  cancelTasksAPI: '/eQAIT.Api/api/CancelTasks',
  vpc: '/eQAIT.Api/api/VPC',
  operationalDetails: '/eQAIT.Api/api/OperationalDetails',
  Plan: '/eQAIT.Api/api/Plan',
  ImageGallery: '/eQAIT.Api/api/ImageGallery',
  SystemIntegration: '/eQAIT.Api/api/SystemIntegration',
}

export default urlConfig;

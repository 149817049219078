import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const Loader = ({ classes }) => (
  <div className={classes.wrapper}>
    <CircularProgress className={classes.loader} />
    Loading ...
  </div>
);

Loader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

Loader.defaultProps = {
  classes: {},
};

export default Loader;

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  data: {},
  isLoading: false,
};


export const deviations = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.DEVIATION_HISTORY_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.DEVIATION_HISTORY_ERROR:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          ...payload,
        },
      };
    case actionTypes.DEVIATION_HISTORY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload,
        },
        isLoading: false,
      };

    default:
      return state;
  }
};

import { combineReducers } from 'redux';
import { siteReviews } from './siteReviews';
import { deviations } from './deviations';
import { resourceAssignment } from './resourceAssignment';
import { sitePlans } from './SitePlan';
import { shiftAssign } from './shiftAssign';
import { taskReviews } from './taskReviews';
import { inspectionChecklist } from './inspectionChecklist';
import { systemIntegration } from './systemIntegration';
import { operatorReportsReducer } from './operatorReportsReducer';
import { reportsReducer } from './reportsReducer';
import { configurationReducer } from './configurationReducer';
import { attendanceTimeSheetReducer } from './attendanceTimeSheetReducer';

const appReducer = combineReducers({
  siteReviews,
  resourceAssignment,
  deviations,
  sitePlans,
  shiftAssign,
  taskReviews,
  inspectionChecklist,
  systemIntegration,
  operatorReportsReducer,
  reportsReducer,
  configurationReducer,
  attendanceTimeSheetReducer
});

export default (state, action) => appReducer(state, action);

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: '',
  userToken: 'VALID',
};

export const user = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.LOGIN_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        data: payload,
        userToken: 'VALID',
        isLoading: false,
        error: '',
      };
    case actionTypes.GET_USER_TOKEN_SUCCESS:
      return {
        ...state,
        userToken: payload,
      };
    case actionTypes.GET_USER_TOKEN_ERROR:
      return {
        ...state,
        userToken: 'INVALID',
      };

    default:
      return state;
  }
};

// export default user;

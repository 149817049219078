
const checkValue = (value) => {
  if (typeof value !== 'string') {
    return value;
  }
  // return value.toLowerCase() === 'yes' ? true : (value.toLowerCase() === 'no' ? false : value || 'N/A');
  return value || 'N/A';
};

const checkImages = (images) => {
  if (images?.length) {
    return images;
  }
  return null;
};

const transformLabels = (label) => {
  const split = label.split(/(?=[A-Z])/);
  return split.reduce((acc, item) => `${acc}${/^[A-Z(\s]*$/.test(acc.slice(-1)) ? '' : ' '}${item}`, '');
};

const getSiteInfo = (data) => data.SiteInfo
  .sort((a, b) => a.value.Sequence > b.value.Sequence ? 1 : -1)
  .reduce((acc, item) => ({ ...acc, [item.key]: {
    ...item.value,
    value: checkValue(item.value.values),
    images: checkImages(item.value.Images),
    // Label: transformLabels(item.value.Label),
  }}), {});


const getSiteSections = (data, infoOnly) => {
  return Object.keys(data).reduce((acc, key) => {
    if (infoOnly && key === 'SiteInformation') {
      const sorted = data[key]
        .sort((a, b) => a.value.Sequence > b.value.Sequence ? 1 : -1)
        .reduce((acc, item) => ({ ...acc, [item.key]: {
          ...item.value,
          value: checkValue(item.value.values),
          images: checkImages(item.value.Images),
        }}), {})

      return { ...acc, [key]: sorted };
    }

    if (!infoOnly && key !== 'SiteInformation') {
      const sorted = data[key].sort((a, b) => a.value.Sequence > b.value.Sequence ? 1 : -1);
      return { ...acc, [key]: sorted };
    }

    return acc;
  }, {});
};

const getApproachesInfo = (data, apprName) => Object.keys(data).map((key) => {
  return data[key]
    .sort((a, b) => a.value.Sequence > b.value.Sequence ? 1 : -1)
    .reduce((acc, item) => ({ ...acc, [item.key]: {
      ...item.value,
      value: checkValue(item.value.values),
      images: checkImages(item.value.Images),
    }}), { ApproachName: key || apprName });
});

const getMainInfo = (data) => Object.keys(data).reduce((acc, key) => key === 'SurveyData' ? acc : ({ ...acc, [key]: {
  value: checkValue(data[key]),
  Label: transformLabels(key),
}}), {});

const getAttendanceInfo = (data) => Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: {
  images: data[key],
  Label: key,
}}), {});

const parseSystemConfiguration = (data) => {
  const systemConfiguration = data.SystemConfiguration;

  const apprName = systemConfiguration.Approaches?.[0] ? 'Approach' : 'Road Side';
  const approaches = getApproachesInfo(systemConfiguration.Approaches?.[0] || systemConfiguration.RoadSide?.[0] || {}, apprName);

  const siteInfo = systemConfiguration.SiteInfo?.length > 1 ? getSiteInfo(systemConfiguration) : {};

  const siteInformation = systemConfiguration.SiteInfo?.length === 1 ? getSiteSections(systemConfiguration.SiteInfo[0], true)?.SiteInformation || [] : [];
  const siteSections = {
    ...(systemConfiguration.SiteInfo?.length === 1 ? getSiteSections(systemConfiguration.SiteInfo[0]) : {}),
    ...(getSiteSections(systemConfiguration.SystemComponents?.[0] || {}) : {}),
  };

  const mainInfo = getMainInfo(data);
  const attendance = getAttendanceInfo(data.Attendance?.[0] || {});

  return {
    SurveyId: data.SurveyId,
    mainInfo,
    attendance,
    siteInfo,
    siteInformation,
    siteSections,
    approaches,
    status: data.Status,
  };
};

const groupMaterials = (taskItem) => {
  const key = 'Code';

  const groupedMaterials = taskItem.Materials.reduce((result, item) => ({
    ...result,
    [item[key]]: {
      code: item[key],
      quantity: taskItem[item[key]?.replace('/', '')],
      materials: [
        ...(result[item[key]]?.materials || []),
        item,
      ],
    },
  }), {});

  return Object.keys(groupedMaterials).map((key) => groupedMaterials[key]);
};

const parseSystemInstallation = (SystemInstallation) => {
  return Object.keys(SystemInstallation).reduce((acc, visit) => {
    return {
      ...acc,
      [visit]: SystemInstallation[visit].map((taskItem) => ({
        ...taskItem,
        groupedMaterials: groupMaterials(taskItem),
      })),
    }
  }, {});
};

export const parseCivilDetails = (task) => {
  const SystemInstallation = task.data.SystemInstallation;
  return {
    TaskId: task.TaskId,
    data: Object.keys(SystemInstallation).reduce((acc, visit) => {
      return {
        ...acc,
        [visit]: SystemInstallation[visit].map((taskItem) => ({
          ...taskItem,
          groupedMaterials: groupMaterials(taskItem),
        })),
      }
    }, {}),
  };
};

export const parseTaskDetails = (data) => {
  return {
    ...data,
    SystemInstallation: parseSystemInstallation(data.SystemInstallation),
    SystemConfiguration: parseSystemConfiguration(data),
  };
};

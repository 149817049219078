export const implementationReportTypes = {
  serialReports: { id: 'installationSerialReport', name: 'Serial', SR: true, implementationReports: true },
  checkList: { id: 'implementationReport', name: 'Checklist', SR: true, implementationReports: true },
  implementaionReview: { id: 'implementaionReviewReport', name: 'Implementation Review', SR: true, implementationReports: true },
  feasibilitySurveyReport: { id: 'feasibilitySurveyReport', name: 'Feasibility Survey Report', SR: true, implementationReports: true },
};
export const implementationReportTypesForAdmin = {
  serialReports: { id: 'installationSerialReport', name: 'Serial', SR: true, implementationReports: true },
  checkList: { id: 'implementationReport', name: 'Checklist', SR: true, implementationReports: true },
  implementaionReview: { id: 'implementaionReviewReport', name: 'Implementation Review', SR: true, implementationReports: true },
  handoverReviewReport: { id: 'HandoverReviewReport', name: 'Handover Review', SR: true, implementationReports: true },
  IltMini: { id: 'IltMini', name: 'Ilt Mini', SR: true, implementationReports: true },
  poleAuditReport: { id: 'poleAuditReport', name: 'Pole Audit Report', SR: true, implementationReports: true },
  feasibilitySurveyReport: { id: 'feasibilitySurveyReport', name: 'Feasibility Survey Report', SR: true, implementationReports: true },
};

import actionTypes from '../actions/actionTypes';
import { parseTaskDetails } from './utils';

const INITIAL_STATE = {
  tasks: [],
  isLoading: false,
  taskDetails: null,
  isDetailsLoading: false,
  error: '',
  detailsError: '',
  reviewSent: '',
  rejectionData: {},
  // reviewHistory: {
  //   data: {},
  //   isLoading: false,
  //   error: null,
  // },
};


export const siteReviews = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {


    // case actionTypes.SITE_REVIEW_HISTORY_LOADING:
    //   return {
    //     ...state,
    //     reviewHistory: {
    //       ...state.reviewHistory,
    //       isLoading: true,
    //       error: null,
    //     },
    //   };
    // case actionTypes.SITE_REVIEW_HISTORY_ERROR:
    //   return {
    //     ...state,
    //     reviewHistory: {
    //       ...state.reviewHistory,
    //       isLoading: false,
    //       error: payload,
    //     },
    //   };
    // case actionTypes.SITE_REVIEW_HISTORY_SUCCESS:
    //   return {
    //     ...state,
    //     reviewHistory: {
    //       ...state.reviewHistory,
    //       data: payload,
    //       isLoading: false,
    //       error: null,
    //     },
    //   };


    case actionTypes.SITE_DETAILS_LOADING:
      return {
        ...state,
        isDetailsLoading: true,
        detailsError: '',
      };
    case actionTypes.SITE_DETAILS_ERROR:
      return {
        ...state,
        isDetailsLoading: false,
        detailsError: payload,
      };
    case actionTypes.SITE_DETAILS_SUCCESS:
      return {
        ...state,
        taskDetails: parseTaskDetails(payload),
        isDetailsLoading: false,
        detailsError: '',
      };

    case actionTypes.SUBMIT_FEEDBACK_LOADING:
      return {
        ...state,
        isDetailsLoading: true,
        error: '',
      };
    case actionTypes.SUBMIT_FEEDBACK_ERROR:
      return {
        ...state,
        isDetailsLoading: false,
        error: payload,
      };
    case actionTypes.SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        isDetailsLoading: false,
        reviewSent: Date.now(),
        error: '',
      };

    case actionTypes.SET_REJECTION_REASON:
      return {
        ...state,
        rejectionData: {
          ...state.rejectionData,
          ...payload,
        },
      };


    default:
      return state;
  }
};

import actionTypes from '../actions/actionTypes';
import { parseDetails, parseCivilDetails } from './utils';
import { statuses } from 'constant';

const INITIAL_STATE = {
  tasks: [],
  surveys: [],
  impSupervisorSurveys: [],
  taskDetailsDocs: [],
  closeTaskDetailsDocs: 0,
  checkListData: [],
  checkListRetrived: [],
  checkListLoading: false,
  surveyIds: [],
  taskData: null,
  illegalTurnLiteDetails: '',
  maintenancePCUTickets: '',
  selectedSurvey: '',
  isLoading: false,
  isDetailsLoading: false,
  error: '',
  detailsError: '',
  reviewSent: '',
  reload: 0,
  reviewHistory: {
    data: {},
    isLoading: false,
    error: null,
  },
  rejectionCategoryStatusState: {
    loading: false,
    data: [],
    error: ''
  },
  rejectionFeasibilityCategoryStatusState: {
    loading: false,
    data: [],
    error: ''
  }
};

const mapSurveys = (state, details) => {
  return state.surveys.map((item) => ({
    ...item,
    ...(item.SurveyId?.toString() === details.SurveyId?.toString() ? details : {}),
  }));
};

const getPendingData = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    const pendingTasks = data[key].filter((item) => (
      item.ReviewStatus === statuses.Pending.name || item.DeviationReviewStatus === statuses.Pending.name
    ));
    const completedTasks = data[key].filter((item) => (
      item.ReviewStatus !== statuses.Pending.name && item.DeviationReviewStatus !== statuses.Pending.name
    ));

    return {
      pending: {
        ...acc.pending,
        [key]: pendingTasks,
        isEmpty: acc.pending.isEmpty && !pendingTasks.length
      },
      completed: {
        ...acc.completed,
        [key]: completedTasks,
        isEmpty: acc.completed.isEmpty && !completedTasks.length
      },
    }
  }, { pending: { isEmpty: true }, completed: { isEmpty: true } });
};

const mapTasks = (state, details) => {
  return state.surveys.map((item) => ({
    ...item,
    ...(item.TaskId?.toString() === details.TaskId?.toString() ? {
      data: details.data,
      ...getPendingData(details.data || {}),
    } : {}),
  }));
};

export const siteReviews = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.SITES_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.SITES_LIST_ERROR:
      return {
        ...state,
        tasks: [],
        isLoading: false,
        error: payload,
      };
    case actionTypes.SITES_LIST_SUCCESS:
      return {
        ...state,
        tasks: payload,
        isLoading: false,
        error: '',
      };

    case actionTypes.ILLEGAL_TURN_LITE_DETAILS_LOADING:
      return {
        ...state,
        isLoading: true,
        illegalTurnLiteDetails: '',
        error: '',
      };
    case actionTypes.ILLEGAL_TURN_LITE_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.ILLEGAL_TURN_LITE_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        illegalTurnLiteDetails: payload,
        error: '',
      };


    case actionTypes.MAINTENANCE_PCU_TICKETS_DETAILS_LOADING:
      return {
        ...state,
        isLoading: true,
        maintenancePCUTickets: '',
        error: '',
      };
    case actionTypes.MAINTENANCE_PCU_TICKETS_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.MAINTENANCE_PCU_TICKETS_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        maintenancePCUTickets: payload,
        error: '',
      };

    case actionTypes.SITE_SURVEY_LIST_LOADING:
      return {
        ...state,
        isDetailsLoading: true,
        error: '',
      };
    case actionTypes.SITE_SURVEY_LIST_ERROR:
      return {
        ...state,
        // tasks: [],
        isDetailsLoading: false,
        error: payload,
      };
    case actionTypes.SITE_SURVEY_LIST_SUCCESS:
      return {
        ...state,
        surveyIds: payload.map((item) => ({ ...item, status: item.Status })),
        surveys: payload,
        selectedSurvey: payload[0]?.SurveyId?.toString() || payload[0]?.TaskId?.toString(),
        isDetailsLoading: false,
        error: '',
      };

    case actionTypes.CHECKLIST_LOADING:
      return {
        ...state,
        checkListLoading: true,
        error: '',
      };
    case actionTypes.CHECKLIST_SUCCESS:
      return {
        ...state,
        checkListData: payload,
        checkListLoading: false,
        // error: payload,
      };
    case actionTypes.CHECKLIST_ERROR:
      return {
        ...state,
        checkListLoading: false,
        error: payload,
      };

    case actionTypes.GET_CHECKLIST_LOADING:
      return {
        ...state,
        checkListLoading: true,
        error: '',
      };
    case actionTypes.GET_CHECKLIST_SUCCESS:
      return {
        ...state,
        checkListRetrived: payload,
        checkListLoading: false,
        // error: payload,
      };
    case actionTypes.GET_CHECKLIST_ERROR:
      return {
        ...state,
        checkListLoading: false,
        error: payload,
      };

    case actionTypes.SUBMIT_CHECKLIST_LOADING:
      return {
        ...state,
        checkListLoading: true,
        error: '',
      };
    case actionTypes.SUBMIT_CHECKLIST_SUCCESS:
      return {
        ...state,
        reload: state.reload + 1,
        checkListLoading: false,
        // error: payload,
      };
    case actionTypes.SUBMIT_CHECKLIST_ERROR:
      return {
        ...state,
        checkListLoading: false,
        error: payload,
      };

    case actionTypes.SITE_REVIEW_HISTORY_LOADING:
      return {
        ...state,
        reviewHistory: {
          ...state.reviewHistory,
          isLoading: true,
          error: null,
        },
      };
    case actionTypes.SITE_REVIEW_HISTORY_ERROR:
      return {
        ...state,
        reviewHistory: {
          ...state.reviewHistory,
          isLoading: false,
          error: payload,
        },
      };
    case actionTypes.SITE_REVIEW_HISTORY_SUCCESS:
      return {
        ...state,
        reviewHistory: {
          ...state.reviewHistory,
          data: payload,
          isLoading: false,
          error: null,
        },
      };

    case actionTypes.SET_SELECTED_SURVEY:
      return {
        ...state,
        selectedSurvey: payload,
      };

    case actionTypes.SITE_DETAILS_LOADING:
      return {
        ...state,
        isDetailsLoading: true,
        detailsError: '',
      };
    case actionTypes.SITE_DETAILS_ERROR:
      return {
        ...state,
        isDetailsLoading: false,
        detailsError: payload,
      };
    case actionTypes.SITE_DETAILS_SUCCESS:
      return {
        ...state,
        surveys: mapSurveys(state, parseDetails(payload)),
        isDetailsLoading: false,
        detailsError: '',
        taskData: payload
      };


    case actionTypes.IMP_SUPERVISOR_TASK_DETAILS_LOADING:
      return {
        ...state,
        isDetailsLoading: true,
        detailsError: '',
      };
    case actionTypes.IMP_SUPERVISOR_TASK_DETAILS_ERROR:
      return {
        ...state,
        isDetailsLoading: false,
        detailsError: payload,

      };
    case actionTypes.IMP_SUPERVISOR_TASK_DETAILS_SUCCESS:
      return {
        ...state,
        impSupervisorSurveys: payload,
        isDetailsLoading: false,
        detailsError: '',
      };

    case actionTypes.TASK_DETAILS_DOCS_ERROR:
      return {
        ...state,
        isDetailsLoading: false,
        taskDetailsDocs: [],
        detailsError: payload,
      };
    case actionTypes.TASK_DETAILS_DOCS_SUCCESS:
      return {
        ...state,
        taskDetailsDocs: payload.data[0].data,
        isDetailsLoading: false,
        detailsError: '',

      };

    case actionTypes.TASK_DETAILS_DOCS_CLOSE_MODAL:
      return {
        ...state,
        closeTaskDetailsDocs: state.closeTaskDetailsDocs + 1,
      };

    case actionTypes.SURVEY_DETAILS_SUCCESS:
      return {
        ...state,
        surveys: mapSurveys(state, parseDetails(payload)),
      };

    case actionTypes.SURVEY_DETAILS_ERROR:
      return {
        ...state,
        surveys: mapSurveys(state, parseDetails(payload)),
      };

    case actionTypes.TASK_DETAILS_LOADING:
      return {
        ...state,
        isDetailsLoading: true,
        detailsError: '',
      };
    case actionTypes.TASK_DETAILS_ERROR:
      return {
        ...state,
        isDetailsLoading: false,
        detailsError: payload,
      };
    case actionTypes.TASK_DETAILS_SUCCESS:
      return {
        ...state,
        surveys: mapTasks(state, parseCivilDetails(payload)),
        isDetailsLoading: false,
        detailsError: '',
      };

    case actionTypes.TASK_SURVEY_DETAILS_SUCCESS:
      return {
        ...state,
        surveys: mapTasks(state, parseCivilDetails(payload)),
      };

    case actionTypes.TASK_SURVEY_DETAILS_ERROR:
      return {
        ...state,
        surveys: mapTasks(state, payload),
      };

    case actionTypes.SUBMIT_FEEDBACK_LOADING:
      return {
        ...state,
        isDetailsLoading: true,
        error: '',
      };
    case actionTypes.SUBMIT_FEEDBACK_ERROR:
      return {
        ...state,
        isDetailsLoading: false,
        error: payload,
      };
    case actionTypes.SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        isDetailsLoading: false,
        // reviewSent: Date.now(),
        error: '',
      };


    case actionTypes.SUBMIT_REJECT_FEEDBACK_LOADING:
      return {
        ...state,
        isDetailsLoading: true,
        error: '',
      };
    case actionTypes.SUBMIT_REJECT_FEEDBACK_ERROR:
      return {
        ...state,
        isDetailsLoading: false,
        error: payload,
      };
    case actionTypes.SUBMIT_REJECT_FEEDBACK_SUCCESS:
      return {
        ...state,
        isDetailsLoading: false,
        // reviewSent: Date.now(),
        error: '',
      };
    // ----------------------------------------------------------------
    case actionTypes.GET_ALL_REJECTION_CATEGORY_STATUS_REQUEST:
      return {
        ...state,
        rejectionCategoryStatusState: {
          loading: true,
          data: [],
          error: ''
        }
      };
    case actionTypes.GET_ALL_REJECTION_CATEGORY_STATUS_SUCCESS:
      return {
        ...state,
        rejectionCategoryStatusState: {
          loading: false,
          data: payload,
          error: ''
        }
      };
    case actionTypes.GET_ALL_REJECTION_CATEGORY_STATUS_ERROR:
      return {
        ...state,
        rejectionCategoryStatusState: {
          loading: false,
          data: [],
          error: payload
        }
      };

    // ----------------------------------------------------------------
    case actionTypes.GET_FEASIBILITY_REJECTION_CATEGORY_STATUS_REQUEST:
      return {
        ...state,
        rejectionFeasibilityCategoryStatusState: {
          loading: true,
          data: [],
          error: ''
        }
      };
    case actionTypes.GET_FEASIBILITY_REJECTION_CATEGORY_STATUS_SUCCESS:
      return {
        ...state,
        rejectionFeasibilityCategoryStatusState: {
          loading: false,
          data: payload,
          error: ''
        }
      };
    case actionTypes.GET_FEASIBILITY_REJECTION_CATEGORY_STATUS_ERROR:
      return {
        ...state,
        rejectionFeasibilityCategoryStatusState: {
          loading: false,
          data: [],
          error: payload
        }
      };

    default:
      return state;
  }
};

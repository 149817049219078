import actionTypes from '../actions/actionTypes';
import { dashboardConfig } from 'config';

const INITIAL_STATE = {
  data: {},
  rawData: [],
  selectedSystem: 0,
  selectedChart: 0,
  sliderAutoplay: dashboardConfig.autoplay,
  dataType: 'numeric',
  colorTheme: window.localStorage.getItem('colorTheme') || 'dark',
  screenMode: 'default',
  connectionStatus: {},
  isLoading: false,
  error: null,
};

// const systemMap = {
//   RedLight: 'Red Light',
//   CCTV: 'CCTV',
//   P2P: 'P2P',
//   FixedSpeed: 'Fixed Speed',
//   TruckExclusion: 'Truck Exclusion',
//   IllegalTurn: 'Illegal Turn',
//   MobileSpeed: 'Mobile Speed (PCU)',
//   VIM: 'VIM',
//   PlateAlteration: 'Plate Alteration',
//   SecurityGate: 'Security Gate',
// };

const dataMap = {
  'Overall Achievement': 'overallAchievement',
  'Achievement': 'achievement',
  'Milestone Baseline Plan Vs Actual': 'milestoneBaseline',
  'Plan Vs Actual': 'deliveryTimeline',
  'Project Milestone': 'projectMilestone',
  'Risk Mitigation Plan': 'riskMitigation',
  'System Achieved': 'systemAchieved',
  'Plan Vs Actual Per System': 'deliveryTimelinePerSystem',
  'Project Milestone Per System': 'projectMilestonePerSystem',
  'System Progress': 'systemProgress',
  'Risks and challenges': 'risksAndChallenges',
  'Per System Achieved': 'achievedWeekly',
  'Forecast Per System Achieved': 'achievedMonthly',
  'Forecast Per System Achieved Yearly': 'achievedYearly',
};

const groupBySystem = (data, key) => {
  return data.reduce((result, item) => {
    if (result[item[key]]) {
      result[item[key]].push(item);
    }
    return {
      ...result,
      [item[key]]: result[item[key]] || [item],
    }
  }, {});
};

const parseData = (data) => {
  const reducedData = data.reduce((acc, item) => ({
    ...acc,
    [dataMap[item.resultname] || item.resultname]: item.data,
  }), {});

  return {
    ...reducedData,
    deliveryTimelinePerSystem: groupBySystem(reducedData.deliveryTimelinePerSystem, 'systemType'),
    projectMilestonePerSystem: groupBySystem(reducedData.projectMilestonePerSystem, 'systemType'),
    milestoneBaseline: groupBySystem(reducedData.milestoneBaseline, 'activity'),
    // achievement: reducedData.achievement.map((item) => ({ ...item, system: systemMap[item.name] })) || [],
  }
};

export const info = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_DASHBOARD_INFO_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.GET_DASHBOARD_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.GET_DASHBOARD_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: parseData(payload),
        rawData: payload,
        error: null,
      };

    case actionTypes.GET_DASHBOARD_LIVE_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: parseData([...state.rawData, ...payload]),
        error: null,
      };

    case actionTypes.SET_SELECTED_SYSTEM:
      return {
        ...state,
        selectedSystem: payload,
        selectedChart: 0,
      };

    case actionTypes.SET_SELECTED_CHART:
      return {
        ...state,
        selectedChart: payload,
      };

    case actionTypes.SET_SLIDER_AUTOPLAY:
      return {
        ...state,
        sliderAutoplay: payload,
      };

    case actionTypes.SET_COLOR_THEME:
      return {
        ...state,
        colorTheme: payload,
      };

    case actionTypes.SET_DATA_TYPE:
      return {
        ...state,
        dataType: payload,
      };

    case actionTypes.SET_SCREEN_MODE:
      return {
        ...state,
        screenMode: payload,
      };

    case actionTypes.SET_CONNECTION_STATUS:
      return {
        ...state,
        connectionStatus: payload,
      };

    default:
      return state;
  }
};

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  installationSerialReports: [],
  implementationReports: [],
  operationSystems: [],
  systemTypes: [],
  baseLineSystems: [],
  systemTypes: [],
  operationCenterList: [],
  systemOwnerList: [],
  operationCityList: [],
  operationRegionList: [],
  cancelTaskList: [],
  implementationReviewReports: [],
  handOverReviewReports: [],
  taskHistoryReports: [],
  poleAuditReports: [],
  attendanceReports: [],
  PCUManagementList: [],
  locationList: [],
  operationSystemsSites: [],
  changed: 0,
  isLoading: true,
  feasibilityChecklistLoader: true,
  feasibilityChecklist: [],
  error: '',
  detailsError: '',
  imageGalleryList: [],
  imageGalleryLoading: false,
  techSupportLoader: false,
  techSupportData: {}
};

export const inspectionChecklist = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.INSPECTION_CHECKLIST_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.GET_INSTALLATION_SERIAL_REPORT_SUCCESS:
      return {
        ...state,
        installationSerialReports: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.GET_INSTALLATION_SERIAL_REPORT_ERROR:
      return {
        ...state,
        installationSerialReports: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.GET_SYSTEM_TYPE_SUCCESS:
      return {
        ...state,
        systemTypes: payload,
        // isLoading: false,
        error: '',
      };
    case actionTypes.GET_OPERATION_SYSTEMS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case actionTypes.GET_OWNER_SUCCESS:
      return {
        ...state,
        systemOwnerList: payload,
        // isLoading: false,
        error: '',
      };
    case actionTypes.GET_OWNER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case actionTypes.GET_OPERATION_CENTER_SUCCESS:
      return {
        ...state,
        operationCenterList: payload,
        // isLoading: false,
        error: '',
      };
    case actionTypes.GET_OPERATION_CENTER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case actionTypes.GET_OPERATION_CITY_SUCCESS:
      return {
        ...state,
        operationCityList: payload,
        // isLoading: false,
        error: '',
      };
    case actionTypes.GET_OPERATION_CITY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case actionTypes.GET_OPERATION_REGION_SUCCESS:
      return {
        ...state,
        operationRegionList: payload,
        // isLoading: false,
        error: '',
      };
    case actionTypes.GET_OPERATION_REGION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case actionTypes.PCUMANAGEMENT_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.PCUMANAGEMENT_LIST_SUCCESS:
      return {
        ...state,
        PCUManagementList: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.PCUMANAGEMENT_LIST_ERROR:
      return {
        ...state,
        PCUManagementList: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.IMAGE_GALLERY_LIST_LOADING:
      return {
        ...state,
        imageGalleryLoading: true,
        error: '',
      };
    case actionTypes.IMAGE_GALLERY_LIST_SUCCESS:
      return {
        ...state,
        imageGalleryList: payload,
        imageGalleryLoading: false,
        error: '',
      };
    case actionTypes.IMAGE_GALLERY_LIST_ERROR:
      return {
        ...state,
        imageGalleryLoading: false,
        error: payload,
      };


    case actionTypes.LOCATION_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.LOCATION_LIST_SUCCESS:
      return {
        ...state,
        locationList: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.LOCATION_LIST_ERROR:
      return {
        ...state,
        locationList: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.IMPLEMENTATION_REPORT_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.IMPLEMENTATION_REPORT_SUCCESS:
      return {
        ...state,
        implementationReports: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.IMPLEMENTATION_REPORT_ERROR:
      return {
        ...state,
        implementationReports: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.HANDOVER_REVIEW_REPORT_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.HANDOVER_REVIEW_REPORT_SUCCESS:
      return {
        ...state,
        handOverReviewReports: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.HANDOVER_REVIEW_REPORT_ERROR:
      return {
        ...state,
        handOverReviewReports: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.TASK_HISTORY_REPORT_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.TASK_HISTORY_REPORT_SUCCESS:
      return {
        ...state,
        taskHistoryReports: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.TASK_HISTORY_REPORT_ERROR:
      return {
        ...state,
        // handOverReviewReports: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.CANCEL_TASK_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.CANCEL_TASK_LIST_SUCCESS:
      return {
        ...state,
        cancelTaskList: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.CANCEL_TASK_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case actionTypes.POLE_AUDIT_REPORT_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.POLE_AUDIT_REPORT_SUCCESS:
      return {
        ...state,
        poleAuditReports: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.POLE_AUDIT_REPORT_ERROR:
      return {
        ...state,
        poleAuditReports: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.CHANGE_PCU_LOCATION_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.CHANGE_PCU_LOCATION_SUCCESS:
      return {
        ...state,
        // implementationReports: payload,
        changed: state.changed + 1,
        isLoading: false,
        error: '',
      };
    case actionTypes.CHANGE_PCU_LOCATION_ERROR:
      return {
        ...state,
        // implementationReports: [],
        isLoading: false,
        error: payload,
      };


    case actionTypes.IMPLEMENTATION_REVIEW_REPORT_SUCCESS:
      return {
        ...state,
        implementationReviewReports: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.IMPLEMENTATION_REVIEW_REPORT_ERROR:
      return {
        ...state,
        implementationReviewReports: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.TASK_DETAILS_REPORT_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.TASK_DETAILS_REPORT_SUCCESS:
      return {
        ...state,
        attendanceReports: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.TASK_DETAILS_REPORT_ERROR:
      return {
        ...state,
        attendanceReports: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.GET_OPERATION_SYSTEMS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.GET_OPERATION_SYSTEMS_SUCCESS:
      return {
        ...state,
        operationSystems: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.GET_OPERATION_SYSTEMS_ERROR:
      return {
        ...state,
        // operationSystem: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.GET_OPERATION_SYSTEMS_SITES_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.GET_OPERATION_SYSTEMS_SITES_SUCCESS:
      return {
        ...state,
        operationSystemsSites: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.GET_OPERATION_SYSTEMS_SITES_ERROR:
      return {
        ...state,
        // operationSystem: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.GET_BASELINE_SYSTEMS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.GET_BASELINE_SYSTEMS_SUCCESS:
      return {
        ...state,
        baseLineSystems: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.GET_BASELINE_SYSTEMS_ERROR:
      return {
        ...state,
        // baseLineSystems: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.GET_SURVEY_FEASIBILITY_CHECKLIST_LOADING:
      return {
        ...state,
        feasibilityChecklistLoader: true,
        error: '',
      };
    case actionTypes.GET_SURVEY_FEASIBILITY_CHECKLIST_SUCCESS:
      return {
        ...state,
        feasibilityChecklist: payload,
        feasibilityChecklistLoader: false,
        error: '',
      };
    case actionTypes.GET_SURVEY_FEASIBILITY_CHECKLIST_ERROR:
      return {
        ...state,
        feasibilityChecklistLoader: false,
        error: payload,
      };
      
      case actionTypes.GET_TECH_SUPPORT_DASHBOARD_REPORT_LOADING:
      return {
        ...state,
        techSupportLoader: true,
        error: '',
      };
    case actionTypes.GET_TECH_SUPPORT_DASHBOARD_REPORT_SUCCESS:
      return {
        ...state,
        techSupportData: payload,
        techSupportLoader: false,
        error: '',
      };
    case actionTypes.GET_TECH_SUPPORT_DASHBOARD_REPORT_ERROR:
      return {
        ...state,
        techSupportLoader: false,
        error: payload,
      };

    default:
      return state;
  }
};

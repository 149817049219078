import actionTypes from '../actions/actionTypes';
import DateFnsUtils from '@date-io/date-fns';

const INITIAL_STATE = {
  newPlans: [],
  plans: [],
  siteDetailsWithStage: {
    sitesList: [],
    groupedSitesList: {}
  },
  egateLiteSiteDetailsWithStage: {
    sitesList: [],
    groupedSitesList: {}
  },
  isLoading: false,
  isSitesUpdated: false,
  sitePlanUploadResponse: [],
  projectTypes: [],
  teamLeaders: [],
  teamLeadersFetched: false,
  selectedSites: [],
  isReset: false,
  siteLocationPlanList: [],
  sitePlanListByPlanner: [],
  siteLocationPlanListDetails: [],
  planDetailsLoading: false
};

const groupBy = (key, items) => {
  return items.reduce((result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }), {});
};

const mapeGateLiteSitesList = (data) => {
  const mapped = data.map((item) => ({
    ...item,
    lat: item.Latitude,
    lng: item.Longitude,
    markerDisplayValues: [
      { label: 'Site Name', value: item.SiteName },
      { label: 'SiteType', value: item.SiteType },
      { label: 'Longitude', value: item.Longitude },
      { label: 'Latitude', value: item.Latitude }
    ],
  }));
  const grouped = groupBy('Beneficiary', mapped.filter((item) => item.lat && item.lng));

  return {
    sitesList: mapped,
    groupedSitesList: grouped,
  }
};

const mapSitesList = (data) => {
  const mapped = data.map((item) => ({
    ...item,
    lat: item.Latitude,
    lng: item.Longitude,
    markerDisplayValues: [
      { label: 'Site Name', value: item.SiteName },
      { label: 'SiteType', value: item.SiteType },
      { label: 'Longitude', value: item.Longitude },
      { label: 'Latitude', value: item.Latitude }
    ],
  }));
  const grouped = groupBy('Stage', mapped.filter((item) => item.lat && item.lng));

  return {
    sitesList: mapped,
    groupedSitesList: grouped,
  }
};

export const sitePlans = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.SITE_PLANS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: ''
      };

    case actionTypes.GET_SITE_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        plans: payload,
        isSitesUpdated: false,
        isReset: false
      };
    case actionTypes.BYPALNNER_GET_SITE_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sitePlanListByPlanner: payload,
        // isSitesUpdated: false,
        // isReset: false
      };

    case actionTypes.SITE_PLANS_LOADING_FALSE:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GET_SITE_PLANS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isSitesUpdated: false
      }

    case actionTypes.SAVE_UPLOAD_SITE_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        sitePlanUploadResponse: payload,
      };

    case actionTypes.SAVE_UPLOAD_SITE_PLANS_ERROR:
      return {
        ...state,
        isLoading: false,
        sitePlanUploadResponse: [],
        error: payload,
      }

    case actionTypes.GET_NEW_SITE_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSitesUpdated: false,
        newPlans: payload.map((plan) => {
          return {
            ...plan,
            PlanDate: new DateFnsUtils().format(plan.PlanDate, 'MM-dd-yyyy')
          }
        })
      };

    case actionTypes.GET_NEW_SITE_PLANS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload
      }

    case actionTypes.SAVE_NEW_SITE_PLANS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        isSitesUpdated: true,
        newPlans: state.newPlans.map((plan) => {
          let planDate = new DateFnsUtils().format(plan.PlanDate, 'MM-dd-yyyy');
          let matchedSite = payload.find((planObj) => planObj.SiteName === plan.SiteName)
          if (matchedSite) {
            return { ...plan, PlanDate: planDate, Result: matchedSite.Status }
          }
          return { ...plan, PlanDate: planDate };
        })
      }

    case actionTypes.SAVE_NEW_SITE_PLANS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload
      }

    case actionTypes.GET_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        projectTypes: payload,
        isLoading: false,
        error: ''
      }

    case actionTypes.GET_PROJECT_TYPE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload
      }

    case actionTypes.GET_TEAM_LEADERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        teamLeaders: payload,
        teamLeadersFetched: true,
      }

    case actionTypes.GET_TEAM_LEADERS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload
      }

    case actionTypes.SET_SELECTED_SITES:
      return {
        ...state,
        selectedSites: [...payload]
      }

    case actionTypes.ASSIGN_TEAM_LEADER_SUCCESS:
      return {
        ...state,
        selectedSites: [],
        isLoading: false,
        error: '',
        isReset: true
      }

    case actionTypes.ASSIGN_TEAM_LEADER_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isReset: false
      }
    case actionTypes.GET_SITE_DETAILS_WITH_STAGE_LOADING:
      return {
        ...state,
        isLoading: true,
        error: ''
      };

    case actionTypes.GET_SITE_DETAILS_WITH_STAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        siteDetailsWithStage: { ...mapSitesList(payload) },
      };

    case actionTypes.GET_EGATE_LITE_SITE_DETAILS_WITH_STAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        egateLiteSiteDetailsWithStage: { ...mapeGateLiteSitesList(payload) },
      };

    case actionTypes.GET_SITE_DETAILS_WITH_STAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        siteDetailsWithStage: {
          sitesList: [],
          groupedSitesList: []
        },
        error: payload,
      }

    case actionTypes.GET_EGATE_LITE_SITE_DETAILS_WITH_STAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        egateLiteSiteDetailsWithStage: {
          sitesList: [],
          groupedSitesList: []
        },
      };


    case actionTypes.GET_SITE_LOCATION_PLAN_LIST_LOADING:
      return {
        ...state,
        isLoading: true,
        error: ''
      };

    case actionTypes.GET_SITE_LOCATION_PLAN_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        siteLocationPlanList: payload,
      };

    case actionTypes.GET_SITE_LOCATION_PLAN_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.GET_SITE_LOCATION_PLAN_LIST_DETAILS_LOADING:
      return {
        ...state,
        planDetailsLoading: true,
        error: '',
      };

    case actionTypes.GET_SITE_LOCATION_PLAN_LIST_DETAILS_SUCCESS:
      return {
        ...state,
        planDetailsLoading: false,
        siteLocationPlanListDetails: payload,
      };

    case actionTypes.GET_SITE_LOCATION_PLAN_LIST_DETAILS_ERROR:
      return {
        ...state,
        planDetailsLoading: false,
      };


    default:
      return state;
  }
};

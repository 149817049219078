import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  dayOpenCloseLoaderError: '',
  dayOpenCloseData: [],
  dayOpenCloseLoader: false
};

export const operatorReportsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_DAY_OPEN_CLOSE_REPORT_LOADING:
      return {
        ...state,
        dayOpenCloseLoader: true,
        dayOpenCloseLoaderError: '',
      };
    case actionTypes.GET_DAY_OPEN_CLOSE_REPORT_SUCCESS:
      return {
        ...state,
        dayOpenCloseData: payload,
        dayOpenCloseLoader: false,
        dayOpenCloseLoaderError: '',
      };
    case actionTypes.GET_DAY_OPEN_CLOSE_REPORT_ERROR:
      return {
        ...state,
        dayOpenCloseLoader: false,
        dayOpenCloseLoaderError: payload,
      };

    default:
      return state;
  }
};

export default {
  SITES_LIST_LOADING: '@REVIEWS_SITES_LIST_LOADING',
  SITES_LIST_SUCCESS: '@REVIEWS_SITES_LIST_SUCCESS',
  SITES_LIST_ERROR: '@REVIEWS_SITES_LIST_ERROR',

  ILLEGAL_TURN_LITE_DETAILS_LOADING: '@ILLEGAL_TURN_LITE_DETAILS_LOADING',
  ILLEGAL_TURN_LITE_DETAILS_SUCCESS: '@ILLEGAL_TURN_LITE_DETAILS_SUCCESS',
  ILLEGAL_TURN_LITE_DETAILS_ERROR: '@ILLEGAL_TURN_LITE_DETAILS_ERROR',

  MAINTENANCE_PCU_TICKETS_DETAILS_LOADING: '@MAINTENANCE_PCU_TICKETS_DETAILS_LOADING',
  MAINTENANCE_PCU_TICKETS_DETAILS_SUCCESS: '@MAINTENANCE_PCU_TICKETS_DETAILS_SUCCESS',
  MAINTENANCE_PCU_TICKETS_DETAILS_ERROR: '@MAINTENANCE_PCU_TICKETS_DETAILS_ERROR',

  SITE_REVIEW_HISTORY_LOADING: '@REVIEWS_SITE_REVIEW_HISTORY_LOADING',
  SITE_REVIEW_HISTORY_SUCCESS: '@REVIEWS_SITE_REVIEW_HISTORY_SUCCESS',
  SITE_REVIEW_HISTORY_ERROR: '@REVIEWS_SITE_REVIEW_HISTORY_ERROR',

  SITE_SURVEY_LIST_LOADING: '@REVIEWS_SITE_SURVEY_LIST_LOADING',
  SITE_SURVEY_LIST_SUCCESS: '@REVIEWS_SITE_SURVEY_LIST_SUCCESS',
  SITE_SURVEY_LIST_ERROR: '@REVIEWS_SITE_SURVEY_LIST_ERROR',

  CHECKLIST_LOADING: '@CHECKLIST_LOADING',
  CHECKLIST_SUCCESS: '@CHECKLIST_SUCCESS',
  CHECKLIST_ERROR: '@CHECKLIST_ERROR',

  GET_CHECKLIST_LOADING: '@GET_CHECKLIST_LOADING',
  GET_CHECKLIST_SUCCESS: '@GET_CHECKLIST_SUCCESS',
  GET_CHECKLIST_ERROR: '@GET_CHECKLIST_ERROR',

  SUBMIT_CHECKLIST_LOADING: '@SUBMIT_CHECKLIST_LOADING',
  SUBMIT_CHECKLIST_SUCCESS: '@SUBMIT_CHECKLIST_SUCCESS',
  SUBMIT_CHECKLIST_ERROR: '@SUBMIT_CHECKLIST_ERROR',

  SET_SELECTED_SURVEY: '@REVIEWS_SET_SELECTED_SURVEY',
  SURVEY_DETAILS_SUCCESS: '@REVIEWS_SURVEY_DETAILS_SUCCESS',
  SURVEY_DETAILS_ERROR: '@REVIEWS_SURVEY_DETAILS_ERROR',
  TASK_SURVEY_DETAILS_SUCCESS: '@REVIEWS_TASK_SURVEY_DETAILS_SUCCESS',
  TASK_SURVEY_DETAILS_ERROR: '@REVIEWS_TASK_SURVEY_DETAILS_ERROR',

  SITE_DETAILS_LOADING: '@REVIEWS_SITE_DETAILS_LOADING',
  SITE_DETAILS_SUCCESS: '@REVIEWS_SITE_DETAILS_SUCCESS',
  SITE_DETAILS_ERROR: '@REVIEWS_SITE_DETAILS_ERROR',

  TASK_DETAILS_LOADING: '@REVIEWS_TASK_DETAILS_LOADING',
  TASK_DETAILS_SUCCESS: '@REVIEWS_TASK_DETAILS_SUCCESS',
  TASK_DETAILS_ERROR: '@REVIEWS_TASK_DETAILS_ERROR',

  IMP_SUPERVISOR_TASK_DETAILS_LOADING: '@IMP_SUPERVISOR_REVIEWS_TASK_DETAILS_LOADING',
  IMP_SUPERVISOR_TASK_DETAILS_SUCCESS: '@IMP_SUPERVISOR_REVIEWS_TASK_DETAILS_SUCCESS',
  IMP_SUPERVISOR_TASK_DETAILS_ERROR: '@IMP_SUPERVISOR_REVIEWS_TASK_DETAILS_ERROR',


  TASK_DETAILS_DOCS_SUCCESS: '@TASK_DETAILS_DOCS_SUCCESS',
  TASK_DETAILS_DOCS_ERROR: '@TASK_DETAILS_DOCS_ERROR',
  TASK_DETAILS_DOCS_CLOSE_MODAL: '@TASK_DETAILS_DOCS_CLOSE_MODAL',

  DEVIATION_HISTORY_LOADING: '@REVIEWS_DEVIATION_HISTORY_LOADING',
  DEVIATION_HISTORY_SUCCESS: '@REVIEWS_DEVIATION_HISTORY_SUCCESS',
  DEVIATION_HISTORY_ERROR: '@REVIEWS_DEVIATION_HISTORY_ERROR',

  ILLEGAL_TURN_DETAILS_LOADING: '@REVIEWS_ILLEGAL_TURN_DETAILS_LOADING',
  ILLEGAL_TURN_DETAILS_SUCCESS: '@REVIEWS_ILLEGAL_TURN_DETAILS_SUCCESS',
  ILLEGAL_TURN_DETAILS_ERROR: '@REVIEWS_ILLEGAL_TURN_DETAILS_ERROR',

  SUBMIT_FEEDBACK_LOADING: '@REVIEWS_SUBMIT_FEEDBACK_LOADING',
  SUBMIT_FEEDBACK_SUCCESS: '@REVIEWS_SUBMIT_FEEDBACK_SUCCESS',
  SUBMIT_FEEDBACK_ERROR: '@REVIEWS_SUBMIT_FEEDBACK_ERROR',

  SUBMIT_REJECT_FEEDBACK_LOADING: '@SUBMIT_REJECT_FEEDBACK_LOADING',
  SUBMIT_REJECT_FEEDBACK_SUCCESS: '@SUBMIT_REJECT_FEEDBACK_SUCCESS',
  SUBMIT_REJECT_FEEDBACK_ERROR: '@SUBMIT_REJECT_FEEDBACK_ERROR',

  PROJECT_TYPES_LOADING: '@ASSIGNMENT_PROJECT_TYPES_LOADING',
  PROJECT_TYPES_SUCCESS: '@ASSIGNMENT_PROJECT_TYPES_SUCCESS',
  PROJECT_TYPES_ERROR: '@ASSIGNMENT_PROJECT_TYPES_ERROR',

  GET_INSPECTORS_LOADING: '@ASSIGNMENT_GET_INSPECTORS_LOADING',
  GET_INSPECTORS_SUCCESS: '@ASSIGNMENT_GET_INSPECTORS_SUCCESS',
  GET_INSPECTORS_ERROR: '@ASSIGNMENT_GET_INSPECTORS_ERROR',

  GET_OPERATORS_LOADING: '@ASSIGNMENT_GET_OPERATORS_LOADING',
  GET_OPERATORS_SUCCESS: '@ASSIGNMENT_GET_OPERATORS_SUCCESS',
  GET_OPERATORS_ERROR: '@ASSIGNMENT_GET_OPERATORS_ERROR',

  GET_CONTRACTOR_LOADING: '@ASSIGNMENT_GET_CONTRACTOR_LOADING',
  GET_CONTRACTOR_SUCCESS: '@ASSIGNMENT_GET_CONTRACTOR_SUCCESS',
  GET_CONTRACTOR_ERROR: '@ASSIGNMENT_GET_CONTRACTOR_ERROR',

  GET_SITE_ROUTE_INFO_LOADING: '@GET_SITE_ROUTE_INFO_LOADING',
  GET_SITE_ROUTE_INFO_SUCCESS: '@GET_SITE_ROUTE_INFO_SUCCESS',
  GET_SITE_ROUTE_INFO_ERROR: '@GET_SITE_ROUTE_INFO_ERROR',

  GET_TASKS_LOADING: '@ASSIGNMENT_GET_TASKS_LOADING',
  GET_TASKS_SUCCESS: '@ASSIGNMENT_GET_TASKS_SUCCESS',
  GET_TASKS_ERROR: '@ASSIGNMENT_GET_TASKS_ERROR',

  ASSIGN_TASKS_LOADING: '@ASSIGNMENT_ASSIGN_TASKS_LOADING',
  ASSIGN_TASKS_SUCCESS: '@ASSIGNMENT_ASSIGN_TASKS_SUCCESS',
  ASSIGN_TASKS_ERROR: '@ASSIGNMENT_ASSIGN_TASKS_ERROR',

  REASSIGN_TASKS_LOADING: '@ASSIGNMENT_REASSIGN_TASKS_LOADING',
  REASSIGN_TASKS_SUCCESS: '@ASSIGNMENT_REASSIGN_TASKS_SUCCESS',
  REASSIGN_TASKS_ERROR: '@ASSIGNMENT_REASSIGN_TASKS_ERROR',

  REMOVE_ASSIGN_TASKS_LOADING: '@ASSIGNMENT_REMOVE_ASSIGN_TASKS_LOADING',
  REMOVE_ASSIGN_TASKS_SUCCESS: '@ASSIGNMENT_REMOVE_ASSIGN_TASKS_SUCCESS',
  REMOVE_ASSIGN_TASKS_ERROR: '@ASSIGNMENT_REMOVE_ASSIGN_TASKS_ERROR',

  TASK_REVIEW_LOADING: '@TASK_REVIES_LOADING',
  TASK_REVIEW_GET_FAULT_SUCCESS: '@TASK_REVIES_GET_FAULT_SUCCESS',
  TASK_REVIEW_GET_FAULT_ERROR: '@TASK_REVIES_GET_FAULT_ERROR',
  TASK_REVIEW_GET_DEBRIEF_SUCCESS: '@TASK_REVIES_GET_DEBRIEF_SUCCESS',
  TASK_REVIEW_GET_DEBRIEF_ERROR: '@TASK_REVIES_GET_DEBRIEF_ERROR',
  TASK_REVIEW_UPDATE_REVIEW_FEEDBACK_SUCCESS: '@TASK_REVIES_UPDATE_REVIEW_FEEDBACK_SUCCESS',
  UPLOADDEBRIEFIMAGES: '@TASK_REVIES_UPLOADDEBRIEFIMAGES',
  RESET_DEBRIEF_IMAGES: '@TASK_REVIES_RESET_DEBRIEF_IMAGES',
  OPERATORFAILUREREASONUPDATED: '@TASK_REVIES_OPERATORFAILUREREASONUPDATED',
  ACCEPTTASKREVIEWSUCCESS: '@TASK_REVIES_ACCEPTTASKREVIEWSUCCESS',
  ACCEPTTASKREVIEWERROR: '@TASK_REVIES_ACCEPTTASKREVIEWERROR',
  UPDATETASKREVIEWSTATUSSUCCESS: '@TASK_REVIES_UPDATETASKREVIEWSTATUSSUCCESS',
  UPDATETASKREVIEWSTATUS: '@TASK_REVIES_UPDATETASKREVIEWSTATUS',
  UPDATETASKREVIEWSTATUSERROR: '@TASK_REVIES_UPDATETASKREVIEWSTATUSERROR',
  TASKREJECTSTORE: '@TASK_REVIES_TASKREJECTSTORE',
  TASK_REVIEW_UPDATE_REVIEW_FEEDBACK_ERROR: '@TASK_REVIES_UPDATE_REVIEW_FEEDBACK_ERROR',
  TASK_REVIEW_GET_AUDIT_TASK_FIELDS_SUCCESS: '@TASK_REVIES_UPDATE_REVIEW_FEEDBACK_SUCCESS',
  TASK_REVIEW_GET_AUDIT_TASK_FIELDS_ERROR: '@TASK_REVIES_UPDATE_REVIEW_FEEDBACK_ERROR',

  SET_TASK_REJECT: '@TASK_REVIEWS_SET_TASK_REJECT',

  SELECT_TASKS: '@ASSIGNMENT_SELECT_TASKS',
  SELECT_TASKS_TYPE: '@ASSIGNMENT_SELECT_TASKS_TYPE',
  SELECTED_SITE: '@ASSIGNMENT_SELECTED_SITE',

  GET_MASTER_DATA_LOOKUP_LOADING: '@ASSIGNMENT_GET_MASTER_DATA_LOOKUP_LOADING',
  GET_MASTER_DATA_LOOKUP_SUCCESS: '@ASSIGNMENT_GET_MASTER_DATA_LOOKUP_SUCCESS',
  GET_MASTER_DATA_LOOKUP_ERROR: '@ASSIGNMENT_GET_MASTER_DATA_LOOKUP_ERROR',

  GET_APPOINTMENT_TASK_FOR_OPERATOR_LOADING: '@ASSIGNMENT_APPOINTMENT_TASK_FOR_OPERATOR_LOADING',
  GET_APPOINTMENT_TASK_FOR_OPERATOR_SUCCESS: '@ASSIGNMENT_APPOINTMENT_TASK_FOR_OPERATOR_SUCCESS',
  GET_APPOINTMENT_TASK_FOR_OPERATOR_ERROR: '@ASSIGNMENT_APPOINTMENT_TASK_FOR_OPERATOR_ERROR',

  SITE_PLANS_LOADING: '@PLANS_SITE_PLANS_LOADING',
  GET_NEW_SITE_PLANS_SUCCESS: '@PLANS_GET_NEW_SITE_PLANS_SUCCESS',
  GET_NEW_SITE_PLANS_ERROR: '@PLANS_GET_NEW_SITE_PLANS_ERROR',
  SAVE_NEW_SITE_PLANS_SUCCESS: '@PLANS_SAVE_NEW_SITE_PLANS_SUCCESS',
  SAVE_NEW_SITE_PLANS_ERROR: '@PLANS_SAVE_NEW_SITE_PLANS_ERROR',
  GET_SITE_PLANS_SUCCESS: '@PLANS_GET_SITE_PLANS_SUCCESS',
  SITE_PLANS_LOADING_FALSE: '@SITE_PLANS_LOADING_FALSE',
  GET_SITE_PLANS_ERROR: '@PLANS_GET_SITE_PLANS_ERROR',
  GET_PROJECT_TYPE_SUCCESS: '@PLANS_GET_PROJECT_TYPE_SUCCESS',
  GET_PROJECT_TYPE_ERROR: '@PLANS_GET_PROJECT_TYPE_ERROR',
  GET_TEAM_LEADERS_SUCCESS: '@PLANS_GET_TEAM_LEADERS_SUCCESS',
  GET_TEAM_LEADERS_ERROR: '@PLANS_GET_TEAM_LEADERS_ERROR',
  SET_SELECTED_SITES: '@PLAN_SET_SELECTED_SITES',
  ASSIGN_TEAM_LEADER_SUCCESS: '@PLANS_ASSIGN_TEAM_LEADER_SUCCESS',
  ASSIGN_TEAM_LEADER_ERROR: '@PLANS_ASSIGN_TEAM_LEADER_ERROR',
  SAVE_UPLOAD_SITE_PLANS_SUCCESS: '@PLAN_SAVE_UPLOAD_SITE_PLANS_SUCCESS',
  SAVE_UPLOAD_SITE_PLANS_ERROR: '@PLAN_SAVE_UPLOAD_SITE_PLANS_ERROR',

  DELETE_SHIFT_SUCCESS: '@CONFIG_DELETE_SHIFT_SUCCESS',
  DELETE_SHIFT_REQUEST: '@CONFIG_DELETE_SHIFT_REQUEST',
  DELETE_SHIFT_ERROR: '@CONFIG_DELETE_SHIFT_ERROR',

  BYPALNNER_GET_SITE_PLANS_SUCCESS: '@CONFIG_GET_SITE_PLANS_SHIFT_SUCCESS',
  BYPALNNER_GET_SITE_PLANS_REQUEST: '@CONFIG_GET_SITE_PLANS_SHIFT_REQUEST',
  BYPALNNER_GET_SITE_PLANS_ERROR: '@CONFIG_GET_SITE_PLANS_SHIFT_ERROR',

  RESET_PASSWORD_SUCCESS: '@RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_REQUEST: '@RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_ERROR: '@RESET_PASSWORD_ERROR',

  DELETE_USER_SUCCESS: '@DELETE_USER_SUCCESS',
  DELETE_USER_REQUEST: '@DELETE_USER_REQUEST',
  DELETE_USER_ERROR: '@DELETE_USER_ERROR',

  FEED_BACK_CANCEL_SUCCESS: '@FEED_BACK_CANCEL_SUCCESS',
  FEED_BACK_CANCEL_REQUEST: '@FEED_BACK_CANCEL_REQUEST',
  FEED_BACK_CANCEL_ERROR: '@FEED_BACK_CANCEL_ERROR',

  GET_RESOURCE_SHIFTS_SUCCESS: '@CONFIG_GET_RESOURCE_SHIFTS_SUCCESS',
  GET_RESOURCE_SHIFTS_ERROR: '@CONFIG_GET_RESOURCE_SHIFTS_ERROR',

  GET_AVAILABLE_SHIFTS_REQUEST: '@CONFIG_GET_AVAILABLE_SHIFTS_REQUEST',
  GET_AVAILABLE_SHIFTS_SUCCESS: '@CONFIG_GET_AVAILABLE_SHIFTS_SUCCESS',
  GET_AVAILABLE_SHIFTS_ERROR: '@CONFIG_GET_AVAILABLE_SHIFTS_ERROR',

  GET_SYSTEM_TYPE_SUCCESS: '@CONFIG_GET_SYSTEM_TYPE_SUCCESS',
  GET_SYSTEM_TYPE_ERROR: '@CONFIG_GET_SYSTEM_TYPE_ERROR',

  GET_TEAMS_SUCCESS: '@CONFIG_GET_TEAMS_SUCCESS',
  GET_TEAMS_REQUEST: '@GET_TEAMS_REQUEST',
  GET_TEAMS_ERROR: '@CONFIG_GET_TEAMS_ERROR',

  SHIFT_UPDATED: '@CONFIG_SHIFT_UPDATED',
  GET_USER_ROLES: '@CONFIG_GET_ROLES_SUCCESS',
  ADD_UPDATE_USER_SUCCESS: '@CONFIG_ADD_UPDATE_USER_SUCCESS',
  START_LOADING: '@CONFIG_START_LOADING',
  ADD_UPDATE_SHIFT_SUCCESS: '@CONFIG_ADD_UPDATE_SHIFT_SUCCESS',
  CONFIG_GET_TEAM_LEADERS_SUCCESS: '@CONFIG_GET_TEAM_LEADERS',
  CONFIG_GET_TEAM_LEADERS_ERRORS: '@CONFIG_GET_TEAM_LEADERS',
  CONFIG_GET_SUPERVISORS_SUCCESS: '@CONFIG_GET_SUPERVISORS_SUCCESS',
  CONFIG_GET_SUPERVISORS_ERRORS: '@CONFIG_GET_SUPERVISORS_ERRORS',
  CONFIG_GET_MANAGERS_SUCCESS: '@CONFIG_GET_MANAGERSSUPERVISORS_SUCCESS',
  CONFIG_GET_MANAGERS_ERRORS: '@CONFIG_GET_MANAGERS_ERRORS',

  INSPECTION_CHECKLIST_LOADING: '@INSPECTION_CHECKLIST_LOADING',
  GET_INSTALLATION_SERIAL_REPORT_SUCCESS: '@GET_INSTALLATION_SERIAL_REPORT_SUCCESS',
  GET_INSTALLATION_SERIAL_REPORT_ERROR: '@GET_INSTALLATION_SERIAL_REPORT_ERROR',

  IMPLEMENTATION_REPORT_LOADING: '@IMPLEMENTATION_REPORT_LOADING',
  IMPLEMENTATION_REPORT_SUCCESS: '@IMPLEMENTATION_REPORT_SUCCESS',
  IMPLEMENTATION_REPORT_ERROR: '@IMPLEMENTATION_REPORT_ERROR',

  HANDOVER_REVIEW_REPORT_LOADING: '@HANDOVER_REVIEW_REPORT_LOADING',
  HANDOVER_REVIEW_REPORT_SUCCESS: '@HANDOVER_REVIEW_REPORT_SUCCESS',
  HANDOVER_REVIEW_REPORT_ERROR: '@HANDOVER_REVIEW_REPORT_ERROR',

  TASK_HISTORY_REPORT_LOADING: '@TASK_HISTORY_REPORT_LOADING',
  TASK_HISTORY_REPORT_SUCCESS: '@TASK_HISTORY_REPORT_SUCCESS',
  TASK_HISTORY_REPORT_ERROR: '@TASK_HISTORY_REPORT_ERROR',

  CANCEL_TASK_LIST_LOADING: '@CANCEL_TASK_LIST_LOADING',
  CANCEL_TASK_LIST_SUCCESS: '@CANCEL_TASK_LIST_SUCCESS',
  CANCEL_TASK_LIST_ERROR: '@CANCEL_TASK_LIST_ERROR',

  POLE_AUDIT_REPORT_LOADING: '@POLE_AUDIT_REPORT_LOADING',
  POLE_AUDIT_REPORT_SUCCESS: '@POLE_AUDIT_REPORT_SUCCESS',
  POLE_AUDIT_REPORT_ERROR: '@POLE_AUDIT_REPORT_ERROR',

  IMPLEMENTATION_REVIEW_REPORT_LOADING: '@IMPLEMENTATION_REVIEW_REPORT_LOADING',
  IMPLEMENTATION_REVIEW_REPORT_SUCCESS: '@IMPLEMENTATION_REPORT_REVIEW_SUCCESS',
  IMPLEMENTATION_REVIEW_REPORT_ERROR: '@IMPLEMENTATION_REVIEW_REPORT_ERROR',

  GET_OPERATION_SYSTEMS_LOADING: '@GET_OPERATION_SYSTEMS_LOADING',
  GET_OPERATION_SYSTEMS_SUCCESS: '@GET_OPERATION_SYSTEMS_SUCCESS',
  GET_OPERATION_SYSTEMS_ERROR: '@GET_OPERATION_SYSTEMS_ERROR',

  GET_OPERATION_SYSTEMS_SITES_LOADING: '@GET_OPERATION_SYSTEMS_SITES_LOADING',
  GET_OPERATION_SYSTEMS_SITES_SUCCESS: '@GET_OPERATION_SYSTEMS_SITES_SUCCESS',
  GET_OPERATION_SYSTEMS_SITES_ERROR: '@GET_OPERATION_SYSTEMS_SITES_ERROR',

  GET_BASELINE_SYSTEMS_LOADING: '@GET_BASELINE_SYSTEMS_LOADING',
  GET_BASELINE_SYSTEMS_SUCCESS: '@GET_BASELINE_SYSTEMS_SUCCESS',
  GET_BASELINE_SYSTEMS_ERROR: '@GET_BASELINE_SYSTEMS_ERROR',

  GET_SYSTEM_TYPE_SUCCESS: '@GET_SYSTEM_TYPE_SUCCESS',
  GET_SYSTEM_TYPE_ERROR: '@GET_SYSTEM_TYPE_ERROR',

  GET_OWNER_SUCCESS: '@GET_OWNER_SUCCESS',
  GET_OWNER_ERROR: '@GET_OWNER_ERROR',

  GET_OPERATION_CENTER_SUCCESS: '@GET_OPERATION_CENTER_SUCCESS',
  GET_OPERATION_CENTER_ERROR: '@GET_OPERATION_CENTER_ERROR',

  GET_OPERATION_CITY_SUCCESS: '@GET_OPERATION_CITY_SUCCESS',
  GET_OPERATION_CITY_ERROR: '@GET_OPERATION_CITY_ERROR',

  GET_OPERATION_REGION_SUCCESS: '@GET_OPERATION_REGION_SUCCESS',
  GET_OPERATION_REGION_ERROR: '@GET_OPERATION_REGION_ERROR',

  TASK_DETAILS_REPORT_LOADING: '@TASK_DETAILS_REPORT_LOADING',
  TASK_DETAILS_REPORT_SUCCESS: '@TASK_DETAILS_REPORT_SUCCESS',
  TASK_DETAILS_REPORT_ERROR: '@TASK_DETAILS_REPORT_ERROR',

  PCUMANAGEMENT_LIST_LOADING: '@PCUMANAGEMENT_LIST_LOADING',
  PCUMANAGEMENT_LIST_SUCCESS: '@PCUMANAGEMENT_LIST_SUCCESS',
  PCUMANAGEMENT_LIST_ERROR: '@PCUMANAGEMENT_LIST_ERROR',

  IMAGE_GALLERY_LIST_LOADING: '@IMAGE_GALLERY_LIST_LOADING',
  IMAGE_GALLERY_LIST_SUCCESS: '@IMAGE_GALLERY_LIST_SUCCESS',
  IMAGE_GALLERY_LIST_ERROR: '@IMAGE_GALLERY_LIST_ERROR',

  LOCATION_LIST_LOADING: '@LOCATION_LIST_LOADING',
  LOCATION_LIST_SUCCESS: '@LOCATION_LIST_SUCCESS',
  LOCATION_LIST_ERROR: '@LOCATION_LIST_ERROR',

  CHANGE_PCU_LOCATION_LOADING: '@CHANGE_PCU_LOCATION_LOADING',
  CHANGE_PCU_LOCATION_SUCCESS: '@CHANGE_PCU_LOCATION_SUCCESS',
  CHANGE_PCU_LOCATION_ERROR: '@CHANGE_PCU_LOCATION_ERROR',

  GET_SITE_DETAILS_WITH_STAGE_LOADING: '@SITE_DETAILS_WITH_STAGE_LOADING',
  GET_SITE_DETAILS_WITH_STAGE_SUCCESS: '@SITE_DETAILS_WITH_STAGE_SUCCESS',
  GET_SITE_DETAILS_WITH_STAGE_ERROR: '@SITE_DETAILS_WITH_STAGE_ERROR',

  GET_EGATE_LITE_SITE_DETAILS_WITH_STAGE_SUCCESS: '@EGATE_LITE_SITE_DETAILS_WITH_STAGE_SUCCESS',
  GET_EGATE_LITE_SITE_DETAILS_WITH_STAGE_ERROR: '@EGATE_LITE_SITE_DETAILS_WITH_STAGE_ERROR',

  GET_SURVEY_FEASIBILITY_CHECKLIST_LOADING: '@SURVEY_FEASIBILITY_CHECKLIST_LOADING',
  GET_SURVEY_FEASIBILITY_CHECKLIST_SUCCESS: '@SURVEY_FEASIBILITY_CHECKLIST_SUCCESS',
  GET_SURVEY_FEASIBILITY_CHECKLIST_ERROR: '@SURVEY_FEASIBILITY_CHECKLIST_ERROR',

  GET_EQUIPMENT_SETUP_FOR_REVIEW_LOADING: '@EQUIPMENT_SETUP_FOR_REVIEW_LOADING',
  GET_EQUIPMENT_SETUP_FOR_REVIEW_SUCCESS: '@EQUIPMENT_SETUP_FOR_REVIEW_SUCCESS',
  GET_EQUIPMENT_SETUP_FOR_REVIEW_ERROR: '@EQUIPMENT_SETUP_FOR_REVIEW_ERROR',

  SAVE_EQUIPMENT_SETUP_REVIEW_LOADING: '@SAVE_EQUIPMENT_SETUP_REVIEW_LOADING',
  SAVE_EQUIPMENT_SETUP_REVIEW_SUCCESS: '@SAVE_EQUIPMENT_SETUP_REVIEW_SUCCESS',
  SAVE_EQUIPMENT_SETUP_REVIEW_ERROR: '@SAVE_EQUIPMENT_SETUP_REVIEW_ERROR',

  GET_SITE_LOCATION_PLAN_LIST_LOADING: '@GET_SITE_LOCATION_PLAN_LIST_LOADING',
  GET_SITE_LOCATION_PLAN_LIST_SUCCESS: '@GET_SITE_LOCATION_PLAN_LIST_SUCCESS',
  GET_SITE_LOCATION_PLAN_LIST_ERROR: '@GET_SITE_LOCATION_PLAN_LIST_ERROR',

  GET_SITE_LOCATION_PLAN_LIST_DETAILS_LOADING: '@GET_SITE_LOCATION_PLAN_LIST_DETAILS_LOADING',
  GET_SITE_LOCATION_PLAN_LIST_DETAILS_SUCCESS: '@GET_SITE_LOCATION_PLAN_LIST_DETAILS_SUCCESS',
  GET_SITE_LOCATION_PLAN_LIST_DETAILS_ERROR: '@GET_SITE_LOCATION_PLAN_LIST_DETAILS_ERROR',

  GET_SYSTEM_INTEGRATION_LOADING: '@GET_SYSTEM_INTEGRATION_LOADING',
  GET_SYSTEM_INTEGRATION_SUCCESS: '@GET_SYSTEM_INTEGRATION_SUCCESS',
  GET_SYSTEM_INTEGRATION_ERROR: '@GET_SYSTEM_INTEGRATION_ERROR',

  GET_TECH_SUPPORT_DASHBOARD_REPORT_LOADING: '@GET_TECH_SUPPORT_DASHBOARD_REPORT_LOADING',
  GET_TECH_SUPPORT_DASHBOARD_REPORT_SUCCESS: '@GET_TECH_SUPPORT_DASHBOARD_REPORT_SUCCESS',
  GET_TECH_SUPPORT_DASHBOARD_REPORT_ERROR: '@GET_TECH_SUPPORT_DASHBOARD_REPORT_ERROR',

  GET_DAY_OPEN_CLOSE_REPORT_LOADING: '@GET_DAY_OPEN_CLOSE_REPORT_LOADING',
  GET_DAY_OPEN_CLOSE_REPORT_SUCCESS: '@GET_DAY_OPEN_CLOSE_REPORT_SUCCESS',
  GET_DAY_OPEN_CLOSE_REPORT_ERROR: '@GET_DAY_OPEN_CLOSE_REPORT_ERROR',

  AUTO_SITE_PLAN_GAP_REPORT_LOADING: '@AUTO_SITE_PLAN_GAP_REPORT_LOADING',
  AUTO_SITE_PLAN_GAP_REPORT_SUCCESS: '@AUTO_SITE_PLAN_GAP_REPORT_SUCCESS',
  AUTO_SITE_PLAN_GAP_REPORT_ERROR: '@AUTO_SITE_PLAN_GAP_REPORT_ERROR',

  AUTO_SITE_PLAN_DETAILS_REPORT_LOADING: '@AUTO_SITE_PLAN_DETAILS_REPORT_LOADING',
  AUTO_SITE_PLAN_DETAILS_REPORT_SUCCESS: '@AUTO_SITE_PLAN_DETAILS_REPORT_SUCCESS',
  AUTO_SITE_PLAN_DETAILS_REPORT_ERROR: '@AUTO_SITE_PLAN_DETAILS_REPORT_ERROR',

  GET_ALL_USERS_LIST_REQUEST: '@GET_ALL_USERS_LIST_REQUEST',
  GET_ALL_USERS_LIST_SUCCESS: '@GET_ALL_USERS_LIST_SUCCESS',
  GET_ALL_USERS_LIST_ERROR: '@GET_ALL_USERS_LIST_ERROR',

  GET_ALL_REJECTION_CATEGORY_STATUS_REQUEST: '@GET_ALL_REJECTION_CATEGORY_STATUS_REQUEST',
  GET_ALL_REJECTION_CATEGORY_STATUS_SUCCESS: '@GET_ALL_REJECTION_CATEGORY_STATUS_SUCCESS',
  GET_ALL_REJECTION_CATEGORY_STATUS_ERROR: '@GET_ALL_REJECTION_CATEGORY_STATUS_ERROR',

  GET_FEASIBILITY_SURVEY_REQUEST: '@GET_FEASIBILITY_SURVEY_REQUEST',
  GET_FEASIBILITY_SURVEY_SUCCESS: '@GET_FEASIBILITY_SURVEY_SUCCESS',
  GET_FEASIBILITY_SURVEY_ERROR: '@GET_FEASIBILITY_SURVEY_ERROR',

  GET_FEASIBILITY_REJECTION_CATEGORY_STATUS_REQUEST: '@GET_FEASIBILITY_REJECTION_CATEGORY_STATUS_REQUEST',
  GET_FEASIBILITY_REJECTION_CATEGORY_STATUS_SUCCESS: '@GET_FEASIBILITY_REJECTION_CATEGORY_STATUS_SUCCESS',
  GET_FEASIBILITY_REJECTION_CATEGORY_STATUS_ERROR: '@GET_FEASIBILITY_REJECTION_CATEGORY_STATUS_ERROR',

  GET_EGATE_LITE_MANAGER_LIST_REQUEST: '@GET_EGATE_LITE_MANAGER_LIST_REQUEST',
  GET_EGATE_LITE_MANAGER_LIST_SUCCESS: '@GET_EGATE_LITE_MANAGER_LIST_SUCCESS',
  GET_EGATE_LITE_MANAGER_LIST_ERROR: '@GET_EGATE_LITE_MANAGER_LIST_ERROR',

  GET_TAHAKOM_REVIEWERS_REQUEST: '@GET_TAHAKOM_REVIEWERS_REQUEST',
  GET_TAHAKOM_REVIEWERS_SUCCESS: '@GET_TAHAKOM_REVIEWERS_SUCCESS',
  GET_TAHAKOM_REVIEWERS_ERROR: '@GET_TAHAKOM_REVIEWERS_ERROR',

  GET_TIMESHEET_LIST_REQUEST: '@GET_TIMESHEET_LIST_REQUEST',
  GET_TIMESHEET_LIST_SUCCESS: '@GET_TIMESHEET_LIST_SUCCESS',
  GET_TIMESHEET_LIST_ERROR: '@GET_TIMESHEET_LIST_ERROR',
  
  GET_JUSTIFICATIONS_LIST_REQUEST: '@GET_JUSTIFICATIONS_LIST_REQUEST',
  GET_JUSTIFICATIONS_LIST_SUCCESS: '@GET_JUSTIFICATIONS_LIST_SUCCESS',
  GET_JUSTIFICATIONS_LIST_ERROR: '@GET_JUSTIFICATIONS_LIST_ERROR',
};

import { siteTypes } from 'constant';
const lang = localStorage.getItem('i18nextLng')
const feedback = {
  label: 'Inspector Feedback',
  fields: [
    'SiteFeasible',
    'FeasibleVendors',
  ],
};

const attendance = {
  label: 'Attendance',
  fields: [
    'Site',
    'Selfie',
  ],
};

export default {
  [siteTypes.redLight.id]: {
    feedback,
    attendance,
    sections: [
      {
        label: 'Site Information',
        sequenceMin: 0,
        sequenceMax: 10,
      },
      {
        label: 'Site Survey Checklist',
        sequenceMin: 10,
        sequenceMax: 1000,
      },

    ],
  },
  [siteTypes.illegalTurn.id]: {
    feedback,
    attendance,
    sections: [
      {
        label: 'Site Information',
        sequenceMin: 0,
        sequenceMax: 1000,
      },
    ],
  },

  default: {
    feedback,
    attendance,
    siteInformation: {
      label: 'Site Information',
      sequenceMin: 0,
      sequenceMax: 1000,
    },
    TaskDocuments: {
      id: 'TaskDocuments',
      label: 'Task Documents',
    },
    TaskProgress: {
      id: 'TaskProgress',
      label: 'Task Progress',
    },
    failureReason: {
      id: 'failureReason',
      label:  lang === 'ar' ? 'سبب فشل المشغل' : 'Operator Failure Reason',
      sequenceMin: 0,
      sequenceMax: 1000,
    },
    TaskInformation: {
      id: 'TaskInformation',
      label: 'Task Information',
    },
    PCUChecklist: {
      id: 'PCUChecklist',
      label: 'PCU Checklist',
    },
  },

}

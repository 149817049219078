export default {
  GET_INFO_LOADING: '@DASHBOARD2_GET_INFO_LOADING',
  GET_INFO_SUCCESS: '@DASHBOARD2_GET_INFO_SUCCESS',
  GET_INFO_ERROR: '@DASHBOARD2_GET_INFO_ERROR',

  GET_DASHBOARD_INFO_LOADING: '@DASHBOARD2_GET_DASHBOARD_INFO_LOADING',
  GET_DASHBOARD_INFO_SUCCESS: '@DASHBOARD2_GET_DASHBOARD_INFO_SUCCESS',
  GET_DASHBOARD_INFO_ERROR: '@DASHBOARD2_GET_DASHBOARD_INFO_ERROR',
  GET_DASHBOARD_LIVE_INFO_SUCCESS: '@DASHBOARD2_GET_DASHBOARD_LIVE_INFO_SUCCESS',

  GET_SITES_LIST_LOADING: '@DASHBOARD_GET_SITES_LIST_LOADING',
  GET_SITES_LIST_SUCCESS: '@DASHBOARD_GET_SITES_LIST_SUCCESS',
  GET_SITES_LIST_ERROR: '@DASHBOARD_GET_SITES_LIST_ERROR',

  SET_SELECTED_SYSTEM: '@DASHBOARD2_SET_SELECTED_SYSTEM',
  SET_SELECTED_CHART: '@DASHBOARD2_SET_SELECTED_CHART',
  SET_SLIDER_AUTOPLAY: '@DASHBOARD2_SET_SLIDER_AUTOPLAY',
  SET_COLOR_THEME: '@DASHBOARD2_SET_COLOR_THEME',
  SET_DATA_TYPE: '@DASHBOARD2_SET_DATA_TYPE',
  SET_SCREEN_MODE: '@DASHBOARD2_SET_SCREEN_MODE',
  SET_IS_CONNECTED: '@DASHBOARD2_SET_IS_CONNECTED',
  SET_CONNECTION_STATUS: '@DASHBOARD2_SET_CONNECTION_STATUS',

};

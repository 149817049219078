import actionTypes from '../actions/actionTypes';
import { isEmpty } from 'utils';

const INITIAL_STATE = {
  taskReviewFaultCodes: [],
  deBriefList: [],
  reviewFeedbackSuccess: {},
  auditTaskFieldsHistory: [],
  rejectionData: {},
  selectedOperationSite: '',
  isLoading: false,
  deBriefImageUpload: [],
  failureReasonUpdated: [],
  updateTaskReviewStatusPayload: [],
  equipmentSetupForReviewData: [],
  error: '',
  detailsError: '',
  reloadEquipmentSetupForReviewData: 0,
  reload: 0
};

export const taskReviews = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.TASK_REVIEW_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.TASK_REVIEW_GET_FAULT_SUCCESS:
      return {
        ...state,
        taskReviewFaultCodes: payload.data,
        isLoading: false,
        error: '',
      };
    case actionTypes.TASK_REVIEW_GET_FAULT_ERROR:
      return {
        ...state,
        taskReviewFaultCodes: [],
        isLoading: false,
        error: payload,
      };
    case actionTypes.TASK_REVIEW_GET_DEBRIEF_SUCCESS:
      return {
        ...state,
        deBriefList: payload.data,
        isLoading: false,
        error: '',
      };
    case actionTypes.TASK_REVIEW_GET_DEBRIEF_ERROR:
      return {
        ...state,
        deBriefList: [],
        isLoading: false,
        error: payload,
      };
    case actionTypes.TASK_REVIEW_UPDATE_REVIEW_FEEDBACK_SUCCESS:
      return {
        ...state,
        reviewFeedbackSuccess: payload,
        isLoading: false,
        error: '',
        reload: state.reload + 1
      };
    case actionTypes.ACCEPTTASKREVIEWSUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        reload: state.reload + 1
      };
    case actionTypes.ACCEPTTASKREVIEWERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.UPDATETASKREVIEWSTATUSSUCCESS:
      return {
        ...state,
        isLoading: false,
        updateTaskReviewStatusPayload: payload,
        error: '',
      };
    case actionTypes.UPDATETASKREVIEWSTATUS:
      return {
        ...state,
        isLoading: true,
        updateTaskReviewStatus: [],
        error: '',
      };
    case actionTypes.UPDATETASKREVIEWSTATUSERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.OPERATORFAILUREREASONUPDATED:
      return {
        ...state,
        failureReasonUpdated: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.UPLOADDEBRIEFIMAGES:
      return {
        ...state,
        deBriefImageUpload: [...state.deBriefImageUpload, payload],
        error: '',
      };
    case actionTypes.RESET_DEBRIEF_IMAGES:
      return {
        ...state,
        deBriefImageUpload: [],
        error: '',
      };
    case actionTypes.TASK_REVIEW_UPDATE_REVIEW_FEEDBACK_ERROR:
      return {
        ...state,
        reviewFeedbackSuccess: {},
        isLoading: false,
        error: payload,
      };

    case actionTypes.TASK_REVIEW_GET_AUDIT_TASK_FIELDS_SUCCESS:
      return {
        ...state,
        auditTaskFieldsHistory: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.TASK_REVIEW_GET_AUDIT_TASK_FIELDS_ERROR:
      return {
        ...state,
        auditTaskFieldsHistory: [],
        isLoading: false,
        error: payload,
      };

    case actionTypes.GET_EQUIPMENT_SETUP_FOR_REVIEW_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.GET_EQUIPMENT_SETUP_FOR_REVIEW_SUCCESS:
      return {
        ...state,
        equipmentSetupForReviewData: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.GET_EQUIPMENT_SETUP_FOR_REVIEW_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case actionTypes.SAVE_EQUIPMENT_SETUP_REVIEW_SUCCESS:
      return {
        ...state,
        reloadEquipmentSetupForReviewData: state.reloadEquipmentSetupForReviewData + 1,
        error: '',
      };
    case actionTypes.SAVE_EQUIPMENT_SETUP_REVIEW_ERROR:
      return {
        ...state,
        error: payload,
      };

    case actionTypes.SELECTED_SITE:
      return {
        ...state,
        selectedOperationSite: payload,
      };
    case actionTypes.SET_TASK_REJECT:
      return {
        ...state,
        rejectionData: isEmpty(payload) ? {} : {
          ...state.rejectionData,
          ...payload,
        },
      };

    default:
      return state;
  }
};

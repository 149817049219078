import actionTypes from '../actions/actionTypes';
import { dashboardConfig } from 'config';

const INITIAL_STATE = {
  data: {},
  rawData: [],
  selectedSystem: '',
  sliderAutoplay: dashboardConfig.autoplay,
  colorTheme: 'default',
  screenMode: 'default',
  connectionStatus: {},
  sitesList: [],
  groupedSitesList: {},
  sitesLoading: false,
  sitesError: null,
  isLoading: false,
  error: null,
};

// const systemMap = {
//   RedLight: 'Red Light',
//   CCTV: 'CCTV',
//   P2P: 'P2P',
//   FixedSpeed: 'Fixed Speed',
//   TruckExclusion: 'Truck Exclusion',
//   IllegalTurn: 'Illegal Turn',
//   MobileSpeed: 'Mobile Speed (PCU)',
//   VIM: 'VIM',
//   PlateAlteration: 'Plate Alteration',
//   SecurityGate: 'Security Gate',
// };

const dataMap = {
  'Overall Achievement': 'overallAchievement',
  'Achievement': 'achievement',
  'Milestone Baseline Plan Vs Actual': 'milestoneBaseline',
  'Plan Vs Actual': 'deliveryTimeline',
  'Project Milestone': 'projectMilestone',
  'Risk Mitigation Plan': 'riskMitigation',
  'System Achieved': 'systemAchieved',
  'Plan Vs Actual Per System': 'deliveryTimelinePerSystem',
  'Project Milestone Per System': 'projectMilestonePerSystem',
  'System Progress': 'systemProgress',
  'Server Time': 'serverTime',
};

const groupBySystem = (data) => {
  const key = 'systemType';

  return data.reduce((result, item) => {
    if (result[item[key]]) {
      result[item[key]].push(item);
    }
    return {
      ...result,
      [item[key]]: result[item[key]] || [item],
    }
  }, {});
};

const parseData = (data) => {
  const reducedData = data.reduce((acc, item) => ({
    ...acc,
    [dataMap[item.resultname] || item.resultname]: item.data,
  }), {});

  return {
    ...reducedData,
    deliveryTimelinePerSystem: groupBySystem(reducedData.deliveryTimelinePerSystem),
    projectMilestonePerSystem: groupBySystem(reducedData.projectMilestonePerSystem),
    // achievement: reducedData.achievement.map((item) => ({ ...item, system: systemMap[item.name] })) || [],
  }
};

const groupBy = (key, items) => {
  return items.reduce((result, item) => ({
    ...result,
    [item[key]]:  [
      ...(result[item[key]] || []),
      item,
    ],
  }), {});
};

const mapSitesList = (data) => {
  const mapped = data.map((item) => ({
    ...item,
    lat: item.Latitude,
    lng: item.Longitude,
    markerDisplayValues: [
      { label: 'Site Name', value: item.SiteName },
      { label: 'SiteType', value: item.SiteType },
      { label: 'Longitude', value: item.Longitude },
      { label: 'Latitude', value: item.Latitude }
    ],
  }));
  const grouped = groupBy('Stage', mapped.filter((item) => item.lat && item.lng));

  return {
    sitesList: mapped,
    groupedSitesList: grouped,
  }
};

export const info = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_DASHBOARD_INFO_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.GET_DASHBOARD_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.GET_DASHBOARD_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: parseData(payload),
        rawData: payload,
        error: null,
      };

    case actionTypes.GET_SITES_LIST_LOADING:
      return {
        ...state,
        sitesLoading: true,
        sitesError: null,
      };
    case actionTypes.GET_SITES_LIST_ERROR:
      return {
        ...state,
        sitesLoading: false,
        sitesError: payload,
      };
    case actionTypes.GET_SITES_LIST_SUCCESS:
      return {
        ...state,
        ...mapSitesList(payload),
        sitesLoading: false,
        sitesError: null,
      };

    case actionTypes.GET_DASHBOARD_LIVE_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: parseData([...state.rawData, ...payload]),
        error: null,
      };

    case actionTypes.SET_SELECTED_SYSTEM:
      return {
        ...state,
        selectedSystem: payload,
      };

    case actionTypes.SET_SLIDER_AUTOPLAY:
      return {
        ...state,
        sliderAutoplay: payload,
      };

    case actionTypes.SET_COLOR_THEME:
      return {
        ...state,
        colorTheme: payload,
      };

    case actionTypes.SET_SCREEN_MODE:
      return {
        ...state,
        screenMode: payload,
      };

    case actionTypes.SET_CONNECTION_STATUS:
      return {
        ...state,
        connectionStatus: payload,
      };

    default:
      return state;
  }
};

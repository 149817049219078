import actionTypes from '../actions/actionTypes';

const initialState = [];

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION:
      return [...state, action.payload];
    case actionTypes.CLEAR_NOTIFICATION:
      return state.filter((n) => n.id !== action.payload.id);
    default:
      return state;
  }
};

export default notifications;

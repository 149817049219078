export const siteTypes = {
  redLight: { id: 'RedLight', name: 'Red Light', SR: true, CI: true },
  illegalTurn: { id: 'IllegalTurn', name: 'Illegal Turn', SR: true, CI: true },
  cctv: { id: 'CCTV', name: 'CCTV', SR: true, CI: true },
  sdaia: { id: 'SDAIA', name: 'SDAIA', SR: true, CI: true },
  fixedSpeed: { id: 'FixedSpeed', name: 'Fixed Speed', SR: true, CI: true },
  mobileSpeed: { id: 'MobileSpeed', name: 'Mobile Speed', SR: false, CI: false },
  p2p: { id: 'P2P', name: 'P2P', SR: true, CI: true },
  pateAlteration: { id: 'PateAlteration', name: 'Plate Alteration', SR: true, CI: true },
  truckExclusion: { id: 'TruckExclusion', name: 'Truck Exclusion', SR: true, CI: true },
  wim: { id: 'WIM', name: 'VIM', SR: true, CI: true },
  securityGate: { id: 'SecurityGate', name: 'Security Gate', SR: true, CI: true },
  ILTMini: { id: 'ILTMini', name: 'ILT Mini', SR: true, CI: true },
  PoleAuditList: { id: 'PoleAuditList', name: 'Pole Audit List', SR: true, CI: true },
  CCTVSiteUpgrade: { id: 'CCTVSiteUpgrade', name: 'CCTVSiteUpgrade', SR: true, CI: true },
  RunAwayTruck: { id: 'Runaway truck', name: 'Runaway truck', SR: true, CI: true },
};

import actionTypes from '../actions/actionTypes';
// import { parseDetails } from './utils';

const INITIAL_STATE = {
  integrationFailedSites:[],
  isLoading: true,
  error: '',
};

export const systemIntegration = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    
    case actionTypes.GET_SYSTEM_INTEGRATION_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.GET_SYSTEM_INTEGRATION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case actionTypes.GET_SYSTEM_INTEGRATION_SUCCESS:
      return {
        ...state,
        integrationFailedSites: payload,
        isLoading: false,  
        error: '',
      };

    default:
      return state;
  }
};

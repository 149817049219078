import { combineReducers } from 'redux';
import { user } from 'modules/home/reducers';
import siteProgress from 'modules/SiteProgress/reducers';
import odoo from 'modules/odoo/reducers';
import dashboard from 'modules/dashboard/reducers';
import dashboard2 from 'modules/dashboardV2/reducers';
import notifications from './notifications';

const appReducer = combineReducers({
  user,
  notifications,
  siteProgress,
  odoo,
  dashboard,
  dashboard2,
});

export default (state, action) => appReducer(state, action);

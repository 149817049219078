import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  resourceShifts: [],
  availableShifts: [],
  systemTypes: [],
  teams: [],
  availableShiftsFetched: false,
  availableRoles: [],
  supervisors: [],
  teamLeaders: [],
  managers: [],
  error: '',
  isShiftUpdated: false,
  isShiftDeleted: false,
  isUserUpdated: false,
  buttonLoading: false,
  closeModal: 0,
  reload: 0
};

export const shiftAssign = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case actionTypes.GET_RESOURCE_SHIFTS_SUCCESS:
      return {
        ...state,
        resourceShifts: payload.map((resource) => {
          return {
            ...resource,
            Password: '',
            MiddleName: resource.MiddleName || '',
            Email: resource.UserName
          }
        }),
        error: '',
        isShiftUpdated: false,
        isUserUpdated: false,
        isLoading: false
      };

    case actionTypes.GET_RESOURCE_SHIFTS_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false
      };
    case actionTypes.GET_AVAILABLE_SHIFTS_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true
      };

    case actionTypes.GET_AVAILABLE_SHIFTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        availableShifts: payload.map((shift) => {
          return {
            ...shift,
            ShiftDisplayName: `${shift.ShiftName} (${shift.StartTime} - ${shift.EndTime})`
          };
        }),
        availableShiftsFetched: true,
        error: ''
      };

    case actionTypes.GET_AVAILABLE_SHIFTS_ERROR:
      return {
        ...state,
        error: payload
      };

    case actionTypes.GET_SYSTEM_TYPE_SUCCESS:
      return {
        ...state,
        systemTypes: payload,
        error: ''
      };

    case actionTypes.GET_SYSTEM_TYPE_ERROR:
      return {
        ...state,
        error: payload
      };
      
      case actionTypes.GET_TEAMS_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true
      };

    case actionTypes.GET_TEAMS_SUCCESS:
      return {
        ...state,
        teams: payload,
        error: '',
        isLoading: false
      };

    case actionTypes.GET_TEAMS_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false
      };

    case actionTypes.SHIFT_UPDATED:
      return {
        ...state,
        isShiftUpdated: true
      };

    case actionTypes.GET_USER_ROLES:
      return {
        ...state,
        availableRoles: payload.sort((a, b) => {

          return a.RoleName.toUpperCase() > b.RoleName.toUpperCase() ? 1 : -1
        })
      };

    case actionTypes.ADD_UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUserUpdated: true,
        isLoading: false
      }

    case actionTypes.ADD_UPDATE_SHIFT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reload: state.reload + 1
      }
    case actionTypes.CONFIG_GET_MANAGERS_SUCCESS:
      return {
        ...state,
        managers: payload,
        error: '',
      };

    case actionTypes.CONFIG_GET_MANAGERS_ERRORS:
      return {
        ...state,
        managers: [],
        error: payload,
        isLoading: false
      };
    case actionTypes.CONFIG_GET_SUPERVISORS_SUCCESS:
      return {
        ...state,
        supervisors: payload,
        error: '',
      };
    case actionTypes.DELETE_SHIFT_REQUEST:
      return {
        ...state,
        isShiftDeleted: false,
        isLoading: true
      }
    case actionTypes.DELETE_SHIFT_SUCCESS:
      return {
        ...state,
        isShiftDeleted: true,
        isLoading: false
      }
    case actionTypes.DELETE_SHIFT_ERROR:
      return {
        ...state,
        isShiftDeleted: false,
        isLoading: false
      }
    case actionTypes.CONFIG_GET_SUPERVISORS_ERRORS:
      return {
        ...state,
        supervisors: [],
        error: payload,
        isLoading: false
      };


    case actionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        buttonLoading: true,
      }
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        closeModal: state.closeModal + 1
      }
    case actionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        buttonLoading: false
      };

    case actionTypes.DELETE_USER_REQUEST:
      return {
        ...state,
        buttonLoading: true,
      }
    case actionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      }
    case actionTypes.DELETE_USER_ERROR:
      return {
        ...state,
        buttonLoading: false
      };

    case actionTypes.FEED_BACK_CANCEL_REQUEST:
      return {
        ...state,
        buttonLoading: true,
      }
    case actionTypes.FEED_BACK_CANCEL_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
      }
    case actionTypes.FEED_BACK_CANCEL_ERROR:
      return {
        ...state,
        buttonLoading: false
      };


    case actionTypes.CONFIG_GET_TEAM_LEADERS_SUCCESS:
      return {
        ...state,
        teamLeaders: payload,
        error: '',
      };

    case actionTypes.CONFIG_GET_TEAM_LEADERS_ERRORS:
      return {
        ...state,
        teamLeaders: [],
        error: payload,
        isLoading: false
      };

    default:
      return state;
  }
};

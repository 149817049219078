import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  attendanceTimeSheetState: {
    loading: false,
    data: [],
    error: ""
  },
  tahakomReviewerState: {
    tahakomReviewers: [],
    tahakomReviewersLoading: true,
    tahakomReviewersError: ""
  },
  justificationsState: {
    loading: true,
    data: [],
    error: ''
  }
};

export const attendanceTimeSheetReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

    case actionTypes.GET_TAHAKOM_REVIEWERS_REQUEST:
      return {
        ...state,
        tahakomReviewerState: {
          tahakomReviewers: [],
          tahakomReviewersLoading: true,
          tahakomReviewersError: ""
        }
      };
    case actionTypes.GET_TAHAKOM_REVIEWERS_SUCCESS:
      return {
        ...state,
        tahakomReviewerState: {
          tahakomReviewers: payload,
          tahakomReviewersLoading: false,
          tahakomReviewersError: ""
        }
      };
    case actionTypes.GET_TAHAKOM_REVIEWERS_ERROR:
      return {
        ...state,
        tahakomReviewerState: {
          tahakomReviewers: [],
          tahakomReviewersLoading: false,
          tahakomReviewersError: payload
        }
      };
    // -----------------------------------------------------
    case actionTypes.GET_TIMESHEET_LIST_REQUEST:
      return {
        ...state,
        attendanceTimeSheetState: {
          data: [],
          loading: true,
          error: ""
        }
      };
    case actionTypes.GET_TIMESHEET_LIST_SUCCESS:
      return {
        ...state,
        attendanceTimeSheetState: {
          data: payload,
          loading: false,
          error: ""
        }
      };
    case actionTypes.GET_TIMESHEET_LIST_ERROR:
      return {
        ...state,
        attendanceTimeSheetState: {
          data: [],
          loading: false,
          error: payload
        }
      };
    // -----------------------------------------------------

    case actionTypes.GET_JUSTIFICATIONS_LIST_REQUEST:
      return {
        ...state,
        justificationsState: {
          data: [],
          loading: true,
          error: ""
        }
      };
    case actionTypes.GET_JUSTIFICATIONS_LIST_SUCCESS:
      return {
        ...state,
        justificationsState: {
          data: payload,
          loading: false,
          error: ""
        }
      };
    case actionTypes.GET_JUSTIFICATIONS_LIST_ERROR:
      return {
        ...state,
        justificationsState: {
          data: [],
          loading: false,
          error: payload
        }
      };
    // -----------------------------------------------------

    default:
      return state;
  }
};

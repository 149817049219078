import { createSelector } from 'reselect'

const checkValue = (value) => {
  if (typeof value !== 'string') {
    return value;
  }
  // return value.toLowerCase() === 'yes' ? true : (value.toLowerCase() === 'no' ? false : value || 'N/A');
  return value || 'N/A';
};

const checkImages = (images) => {
  if (images?.length) {
    return images;
  }
  return null;
};

const transformLabels = (label) => {
  const split = label.split(/(?=[A-Z])/);
  return split.reduce((acc, item) => `${acc}${/^[A-Z(\s]*$/.test(acc.slice(-1)) ? '' : ' '}${item}`, '');
};

const getSiteInfo = (data) => data.SiteInfo
  .sort((a, b) => a.value.Sequence > b.value.Sequence ? 1 : -1)
  .reduce((acc, item) => ({
    ...acc, [item.key]: {
      ...item.value,
      value: checkValue(item.value.values),
      images: checkImages(item.value.Images),
      // Label: transformLabels(item.value.Label),
    }
  }), {});


const getSiteSections = (data, infoOnly) => {
  return Object.keys(data).reduce((acc, key) => {
    if (infoOnly && key === 'SiteInformation') {
      const sorted = data[key]
        .sort((a, b) => a.value.Sequence > b.value.Sequence ? 1 : -1)
        .reduce((acc, item) => ({
          ...acc, [item.key]: {
            ...item.value,
            value: checkValue(item.value.values),
            images: checkImages(item.value.Images),
          }
        }), {})

      return { ...acc, [key]: sorted };
    }

    if (!infoOnly && key !== 'SiteInformation') {
      const sorted = data[key].sort((a, b) => a.value.Sequence > b.value.Sequence ? 1 : -1);
      return { ...acc, [key]: sorted };
    }

    return acc;
  }, {});
};

const getApproachesInfo = (data, apprName) => Object.keys(data).map((key) => {
  return data[key]
    .sort((a, b) => a.value.Sequence > b.value.Sequence ? 1 : -1)
    .reduce((acc, item) => ({
      ...acc, [item.key]: {
        ...item.value,
        value: checkValue(item.value.values),
        images: checkImages(item.value.Images),
      }
    }), { ApproachName: key || apprName });
});

const getMainInfo = (data) => Object.keys(data).reduce((acc, key) => key === 'SurveyData' ? acc : ({
  ...acc, [key]: {
    value: checkValue(data[key]),
    Label: transformLabels(key),
  }
}), {});

const getAttendanceInfo = (data) => Object.keys(data).reduce((acc, key) => ({
  ...acc, [key]: {
    images: data[key],
    Label: key,
  }
}), {});

export const parseDetails = ({ data, status }) => {
  const surveyData = data?.SurveyData;
  if (!surveyData) {
    return {
      SurveyId: data?.SurveyId || data?.TaskId,
      error: data?.error,
      status,
    };
  }
  const apprName = surveyData.Approaches?.[0] ? 'Approach' : 'Road Side';

  const approaches = getApproachesInfo(surveyData.Approaches?.[0] || surveyData.RoadSide?.[0] || {}, apprName);
  const siteInfo = surveyData.SiteInfo.length > 1 ? getSiteInfo(surveyData) : {};
  const siteInformation = surveyData.SiteInfo.length === 1 ? getSiteSections(surveyData.SiteInfo[0], true)?.SiteInformation || [] : [];
  const siteSections = surveyData.SiteInfo.length === 1 ? getSiteSections(surveyData.SiteInfo[0]) : {};
  const failureReason = surveyData.SiteInfo.length === 1 ? getSiteSections(surveyData.SiteInfo[0])?.FailureReason || [] : [];
  const TaskInformation = surveyData.SiteInfo.length === 1 ? getSiteSections(surveyData.SiteInfo[0])?.TaskInformation || [] : [];
  const PCUChecklist = surveyData.SiteInfo.length === 1 ? getSiteSections(surveyData.SiteInfo[0])?.PCUChecklist || [] : [];
  const mainInfo = getMainInfo(data);
  const attendance = getAttendanceInfo(data.Attendance?.[0] || {});
  // const lanes = surveyData.Lanes?.[0];

  return {
    SurveyId: data.SurveyId || data.TaskId,
    mainInfo,
    attendance,
    siteInfo,
    siteInformation,
    siteSections,
    approaches,
    status,
    failureReason,
    TaskInformation,
    PCUChecklist
    // lanes,
  };
};

const groupMaterials = (taskItem) => {
  const key = 'Code';

  const groupedMaterials = taskItem.Materials.reduce((result, item) => ({
    ...result,
    [item[key]]: {
      code: item[key],
      quantity: taskItem[item[key]?.replace('/', '')],
      materials: [
        ...(result[item[key]]?.materials || []),
        item,
      ],
    },
  }), {});

  return Object.keys(groupedMaterials).map((key) => groupedMaterials[key]);
};

export const parseCivilDetails = (task) => {
  return {
    ...task,
    data: Object.keys(task.data).reduce((acc, visit) => {
      return {
        ...acc,
        [visit]: task.data[visit].map((taskItem) => ({
          ...taskItem,
          groupedMaterials: groupMaterials(taskItem),
        })),
      }
    }, {}),
  };
};

const getSurveyDetails = ({ surveys = [], selectedSurvey = '' }) => {
  return surveys.find((item) => {
    return item.SurveyId?.toString() === selectedSurvey.toString() ||
      item.TaskId?.toString() === selectedSurvey.toString();
  }) || {};
};

export const getStatus = createSelector(
  state => state.siteProgress.siteReviews,
  siteReviews => getSurveyDetails(siteReviews || {})?.status || '',
)

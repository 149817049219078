import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

let configureStore;

if (process.env.NODE_ENV === 'production') {
  configureStore = (initialState = {}) => {
    const createStoreWithMiddleware = compose(applyMiddleware(thunk))(createStore);
    return createStoreWithMiddleware(rootReducer, initialState);
  };
} else {
  configureStore = (initialState = {}) => {
    const createStoreWithMiddleware = compose(applyMiddleware(thunk, createLogger()))(createStore);
    const store = createStoreWithMiddleware(
      rootReducer,
      initialState,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
    if (module.hot) {
      module.hot.accept('../reducers', () =>
        /* eslint-disable-next-line */
        store.replaceReducer(rootReducer),
      );
    }

    return store;
  };
}

export default configureStore;

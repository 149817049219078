const dashboardConfig = {
  autoplay: true,
  autoplaySpeed: 10 * 1000,
  overallChangeTime: 8 * 1000,
  projectsChangeTime: 8 * 1000,
  speed: 3 * 1000,

  minValue: 0.7,
  maxValue: 0.8,
};

export default dashboardConfig;

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    color: theme.palette.text.white,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    height: '100%',
    zIndex: 1000,
  },
  loader: {
    display: 'block',
    margin: theme.spacing(0, 0, 3),
  },
});

export default styles;

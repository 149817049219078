import { colors } from 'theme'

export const statuses = {
  Draft: {
    name: 'Draft',
    color1: colors.orange,
    color2: '',
    iconClass: 'icon-24-draft',
  },
  Pending: {
    name: 'Pending',
    color1: colors.orange,
    color2: '',
    iconClass: '',
  },
  Approved: {
    name: 'Approved',
    color1: colors.green,
    color2: '',
    iconClass: '',
  },
  Accepted: {
    name: 'Accepted',
    color1: colors.green,
    color2: '',
    iconClass: '',
  },
  'Accepted System Installation': {
    name: 'Accepted System Installation',
    color1: colors.green,
    color2: '',
    iconClass: '',
  },
  'Accepted System Configuration': {
    name: 'Accepted System Configuration',
    color1: colors.green,
    color2: '',
    iconClass: '',
  },
  'Accepted Site': {
    name: 'Accepted Site',
    color1: colors.green,
    color2: '',
    iconClass: '',
  },
  'Conditional Approved': {
    name: 'Conditional Approved',
    color1: colors.green,
    color2: '',
    iconClass: '',
  },
  Corrected: {
    name: 'Corrected',
    color1: colors.blue,
    color2: '',
    iconClass: '',
  },
  Completed: {
    name: 'Completed',
    color1: colors.green,
    color2: '',
    iconClass: '',
  },
  'In-Progress': {
    name: 'In Progress',
    color1: colors.blue,
    color2: '',
    iconClass: '',
  },
  EditRequested: {
    name: 'Edit Requested',
    color1: colors.orange,
    color2: '',
    iconClass: '',
  },
  Rejected: {
    name: 'Rejected',
    color1: colors.red,
    color2: '',
    iconClass: '',
  },
  'Rejected by Operation': {
    name: 'Rejected by Operation',
    color1: colors.red,
    color2: '',
    iconClass: '',
  },
  'Rejected by Implementation': {
    name: 'Rejected by Implementation',
    color1: colors.red,
    color2: '',
    iconClass: '',
  },


  SubmittedToReview: {
    name: 'Submitted To Review',
    color1: colors.blue,
  },
  SubmittedToSupervisor: {
    name: 'Submitted To Supervisor',
    color1: colors.blue,
  },
  'Approved By TeamLeader': {
    name: 'Approved By Team Leader',
    color1: colors.green,
  },
  'Approved By Supervisor': {
    name: 'Approved By Supervisor',
    color1: colors.green,
  },
  'Rejected By TeamLeader': {
    name: 'Rejected By Team Leader',
    color1: colors.red,
  },
  'Rejected By Supervisor': {
    name: 'Rejected By Supervisor',
    color1: colors.red,
  },
};

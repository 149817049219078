export default (color) => ({
  fontFamily: 'OpenSans, Calibri, sans-serif',
  h1: {
    fontSize: 32,
    letterSpacing: 0,
    fontWeight: 700,
    color,
  },
  h2: {
    fontSize: 24,
    letterSpacing: 0,
    fontWeight: 700,
    color,
  },
  h3: {
    fontSize: 20,
    letterSpacing: 0,
    fontWeight: 700,
    color,
  },
  h4: {
    fontSize: 16,
    letterSpacing: 0.8,
    fontWeight: 600,
    color,
  },
  h5: {
    fontSize: 14,
    letterSpacing: 0.5,
    fontWeight: 600,
    color,
  },
  h6: {
    fontSize: 12,
    letterSpacing: 0.5,
    fontWeight: 600,
    color,
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    color,
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    color,
  },
  caption: {
    fontSize: 12,
    fontWeight: 400,
    color,
  },
});

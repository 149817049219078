import React from 'react';
import {
  FeaturedIcon,
  SettingsIcon,
  InvoiceIcon,
  PrivacyIcon,
} from 'commonComponents/Icons';
import supervisorUserGuide from './tutorial/docs/supervisorUserGuide.pdf';
import iOSActivationUserGuide from './tutorial/docs/iOSActivationUserGuide.pdf';
import operatorUserGuide from './tutorial/docs/operatorUserGuide.pdf';
import teamLeaderUserGuide from './tutorial/docs/teamLeaderUserGuide.pdf';

export const userGuidesMenus = [
  {
    id: 'supervisor',
    type: 'PDF',
    title: 'Supervisor',
    name: 'Supervisor',
    file: supervisorUserGuide,
    icon: <FeaturedIcon />,
  },
  {
    id: 'teamleader',
    type: 'PDF',
    title: 'Team leader',
    name: 'Team leader',
    file: teamLeaderUserGuide,
    icon: <SettingsIcon />,
  },
  {
    id: 'operator',
    type: 'PDF',
    title: 'Operator',
    name: 'Operator',
    file: operatorUserGuide,
    icon: <InvoiceIcon />,
  },
  {
    id: 'iOSActivation',
    type: 'PDF',
    title: 'iOS Activation',
    name: 'iOS Activation',
    file: iOSActivationUserGuide,
    icon: <PrivacyIcon />,
  },
];

export const userGuideVideoMenusTest = [
  {
    id: 'operatorPart1',
    title: 'Operator Training Part 1',
    name: 'Operator Training Part 1',
    video: 'https://egatetest.tahakom.com/web/image/30226?unique=bb8cca013cb5f70dd089d8cd12bdc445c1f979e2',
    icon: <FeaturedIcon />,
  },
  {
    id: 'operatorPart2',
    title: 'Operator Training Part 2',
    name: 'Operator Training Part 2',
    video: 'https://egatetest.tahakom.com/web/image/30225?unique=fa1e461d8d75367efc5db271c1fb056a194c1a9f',
    icon: <SettingsIcon />,
  },
  {
    id: 'supervisorAndTeamleaderAssigningTasksPart1',
    title: 'Supervisor and Team Leader Assign Task Part 1',
    name: 'Supervisor and Team Leader Assign Task Part 1',
    video: 'https://egatetest.tahakom.com/web/image/30235?unique=723cf205d78a80467c446231fcbcf9c379a27a68',
    icon: <InvoiceIcon />,
  },
  {
    id: 'teamleaderTaskReviewPart2',
    title: 'Supervisor Task Review Part 2',
    name: 'Supervisor Task Review Part 2',
    video: 'https://egatetest.tahakom.com/web/image/30236?unique=8c170a5192e0325c6441055172f05a3ea7547647',
    icon: <PrivacyIcon />,
  },
  {
    id: 'supervisorTaskReviewPart3',
    title: 'Supervisor Task Review Part 3',
    name: 'Supervisor Task Review Part 3',
    video: 'https://egatetest.tahakom.com/web/image/30234?unique=0cdeb7c97515d2a43d3e0259f8a732c99ba888d2',
    icon: <PrivacyIcon />,
  }
];

export const userGuideVideoMenusProd = [
  {
    id: 'operatorPart1',
    title: 'Operator Training Part 1',
    name: 'Operator Training Part 1',
    video: 'https://egate.tahakom.com/web/image/35847?unique=bb8cca013cb5f70dd089d8cd12bdc445c1f979e2',
    icon: <FeaturedIcon />,
  },
  {
    id: 'operatorPart2',
    title: 'Operator Training Part 2',
    name: 'Operator Training Part 2',
    video: 'https://egate.tahakom.com/web/image/35848?unique=fa1e461d8d75367efc5db271c1fb056a194c1a9f',
    icon: <SettingsIcon />,
  },
  {
    id: 'supervisorAndTeamleaderAssigningTasksPart1',
    title: 'Supervisor and Team Leader Assign Task Part 1',
    name: 'Supervisor and Team Leader Assign Task Part 1',
    video: 'https://egate.tahakom.com/web/image/35849?unique=723cf205d78a80467c446231fcbcf9c379a27a68',
    icon: <InvoiceIcon />,
  },
  {
    id: 'teamleaderTaskReviewPart2',
    title: 'Supervisor Task Review Part 2',
    name: 'Supervisor Task Review Part 2',
    video: 'https://egate.tahakom.com/web/image/35850?unique=8c170a5192e0325c6441055172f05a3ea7547647',
    icon: <PrivacyIcon />,
  },
  {
    id: 'supervisorTaskReviewPart3',
    title: 'Supervisor Task Review Part 3',
    name: 'Supervisor Task Review Part 3',
    video: 'https://egate.tahakom.com/web/image/35851?unique=0cdeb7c97515d2a43d3e0259f8a732c99ba888d2',
    icon: <PrivacyIcon />,
  }
];

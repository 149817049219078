import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  isLoading: false,
  autoSitePlanGapReportsList: [],
  autoSitePlanDetailsReportsList: [],
  error: null,
  feasibilitySurveyReport: {
    loading: false,
    data: [],
    error: ''
  }
};

export const reportsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.AUTO_SITE_PLAN_GAP_REPORT_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.AUTO_SITE_PLAN_GAP_REPORT_SUCCESS:
      return {
        ...state,
        autoSitePlanGapReportsList: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.AUTO_SITE_PLAN_GAP_REPORT_ERROR:
      return {
        ...state,
        autoSitePlanGapReportsList: [],
        isLoading: false,
        error: payload,
      };
    case actionTypes.AUTO_SITE_PLAN_DETAILS_REPORT_LOADING:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case actionTypes.AUTO_SITE_PLAN_DETAILS_REPORT_SUCCESS:
      return {
        ...state,
        autoSitePlanDetailsReportsList: payload,
        isLoading: false,
        error: '',
      };
    case actionTypes.AUTO_SITE_PLAN_DETAILS_REPORT_ERROR:
      return {
        ...state,
        autoSitePlanDetailsReportsList: [],
        isLoading: false,
        error: payload,
      };
    // ----------------------------------------
    case actionTypes.GET_FEASIBILITY_SURVEY_REQUEST:
      return {
        ...state,
        feasibilitySurveyReport: {
          loading: true,
          data: [],
          error: ""
        }
      };
    case actionTypes.GET_FEASIBILITY_SURVEY_SUCCESS:
      return {
        ...state,
        feasibilitySurveyReport: {
          loading: false,
          data: payload,
          error: ""
        }
      };
    case actionTypes.GET_FEASIBILITY_SURVEY_ERROR:
      return {
        ...state,
        feasibilitySurveyReport: {
          loading: false,
          data: [],
          error: payload
        }
      };
    default:
      return state;
  }
};

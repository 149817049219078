
const lang = localStorage.getItem('i18nextLng')
export const configTypes = {
  // resources: { id: 'resources', name: lang === 'ar' ? 'الموارد' : 'Resources', SR: true, configuration: true },
  allUsers: { id: 'allUsers', name: lang === 'ar' ? 'جميع المستخدمين' : 'Users', SR: true, configuration: true },
  bulkAssignShift: { id: 'bulkAssignShift', name: lang === 'ar' ? 'تحويل التعيين المجمع' : 'Bulk Assign Shift', SR: true, configuration: true },
};
export const configTypesPCU= {
  // resources: { id: 'resources', name: lang === 'ar' ? 'الموارد' : 'Resources', SR: true, configuration: true },
  allUsers: { id: 'allUsers', name: lang === 'ar' ? 'جميع المستخدمين' : 'Users', SR: true, configuration: true },
  bulkAssignShift: { id: 'bulkAssignShift', name: lang === 'ar' ? 'تحويل التعيين المجمع' : 'Bulk Assign Shift', SR: true, configuration: true },
  pcu: { id: 'operationSystem', name: 'PCU', SR: true, configuration: true },
  sites: { id: 'operationSystem', name: 'Sites', SR: true, configuration: true },
};
export const configTypesRasedAdmin = {
  // resources: { id: 'resources', name: lang === 'ar' ? 'الموارد' : 'Resources', SR: true, configuration: true },
  allUsers: { id: 'allUsers', name: lang === 'ar' ? 'جميع المستخدمين' : 'Users', SR: true, configuration: true },
  shifts: { id: 'shifts', name: lang === 'ar' ? 'التحولات' : 'Shifts', SR: true, configuration: true },
  teams: { id: 'teams', name: lang === 'ar' ? 'فرق' : 'Teams', SR: true, configuration: true },
  pcu: { id: 'operationSystem', name: 'PCU', SR: true, configuration: true },
  sites: { id: 'operationSystem', name: 'Sites', SR: true, configuration: true },
};


export const reportstabsPCU = {
  taskDetailsReportPCU: { id: 'taskDetailsReportPCU', name: 'PCU Task Details Report', reports: true },
  autoSiteplanDetails: { id: 'autoSiteplanDetails', name: 'Auto Site Plan Details', reports: true },
  autoSiteplanSummary: { id: 'autoSiteplanSummary', name: 'Auto Site Plan Summary', reports: true },
};
export const reportstabsMS = {
  taskDetailsReportMS: { id: 'taskDetailsReportMS', name: 'MS Task Details Report', reports: true },
  autoSiteplanDetails: { id: 'autoSiteplanDetails', name: 'Auto Site Plan Details', reports: true },
  autoSiteplanSummary: { id: 'autoSiteplanSummary', name: 'Auto Site Plan Summary', reports: true },
};
export const reportstabsCCTV = {
  taskDetailsReportCCTV: { id: 'taskDetailsReportCCTV', name: 'CCTV Task Details Report', reports: true },
  autoSiteplanDetails: { id: 'autoSiteplanDetails', name: 'Auto Site Plan Details', reports: true },
  autoSiteplanSummary: { id: 'autoSiteplanSummary', name: 'Auto Site Plan Summary', reports: true },
};
export const reportstabsVIM = {
  taskDetailsReportVIM: { id: 'taskDetailsReportVIM', name: 'VIM Task Details Report', reports: true },
  autoSiteplanDetails: { id: 'autoSiteplanDetails', name: 'Auto Site Plan Details', reports: true },
  autoSiteplanSummary: { id: 'autoSiteplanSummary', name: 'Auto Site Plan Summary', reports: true },
};
export const reportstabsALL = {
  taskDetailsReportPCU: { id: 'taskDetailsReportPCU', name: 'PCU Task Details Report', reports: true },
  taskDetailsReportMS: { id: 'taskDetailsReportMS', name: 'MS Task Details Report', reports: true },
  taskDetailsReportCCTV: { id: 'taskDetailsReportCCTV', name: 'CCTV Task Details Report', reports: true },
  taskDetailsReportVIM: { id: 'taskDetailsReportVIM', name: 'VIM Task Details Report', reports: true },
  autoSiteplanDetails: { id: 'autoSiteplanDetails', name: 'Auto Site Plan Details', reports: true },
  autoSiteplanSummary: { id: 'autoSiteplanSummary', name: 'Auto Site Plan Summary', reports: true },
  // maintenanceGAPreport: { id: 'maintenanceGAPreport', name: 'Maintenance GAP Report', reports: true },
};

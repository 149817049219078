import { combineReducers } from 'redux';
import { siteReviews } from './siteReviews';
import { deviations } from './deviations';
// import { taskReviews } from './taskReviews'

const appReducer = combineReducers({
  siteReviews,
  deviations,
});

export default (state, action) => appReducer(state, action);

import actionTypes from '../actions/actionTypes';

const INITIAL_STATE = {
  egateListManagerListState: {
    loading: false,
    data: [],
    error: ''
  }
};

export const configurationReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_EGATE_LITE_MANAGER_LIST_REQUEST:
      return {
        ...state,
        egateListManagerListState: {
          loading: true,
          data: [],
          error: ''
        }
      };
    case actionTypes.GET_EGATE_LITE_MANAGER_LIST_SUCCESS:
      return {
        ...state,
        egateListManagerListState: {
          loading: false,
          data: payload,
          error: ''
        }
      };
    case actionTypes.GET_EGATE_LITE_MANAGER_LIST_ERROR:
      return {
        ...state,
        egateListManagerListState: {
          loading: false,
          data: [],
          error: payload
        }
      };
    default:
      return state;
  }
};
